module.exports = {
	language: {
		name: 'EN',
		list: [{
				title: '关于大赛',
				to: '/aboutContest',
				name: 'AboutContest'
			},
			{
				title: '线上展览',
				to: '/onlineDisplay',
				name: 'onlineDisplay'
			},
			{
				title: '产品展示',
				to: '/productDisplay',
				name: 'ProductDisplay'
			},
			{
				title: '综合资讯',
				to: '/information',
				name: 'InFormation'
			},
			{
				title: '专家智库',
				to: '/expertSthink',
				name: 'ExpertSthink'
			},
			{
				title: '报名参赛',
				to: '/foruploading',
				name: 'Forinformation'
			}
		]
	},
	user: {
		login: '登录',
		register: '注册',
		loginUsername: '请输入邮箱/手机号',
	},
	home: {
		contestTitle: '第二届人民文创国际创意大赛',
		contestName: '“数创新生”',
		ImmediateName: '立即参赛',
		contestBrief: '人民文创国际创意大赛简介',
		brief: '打造具有全球影响力的文创大赛品牌，汇聚和沉淀优质文创产业资源',
		knowaBout: '了解更多',
		trackName: '赛道介绍',
		IntroductionBrief: '评委简介',
		information: '综合资讯',
		partner: '合作伙伴',
		more: '更多精彩',
		wmore: '查看更多'
	},
	entry: {
		serial: '作品编号：',
		category: ['本届参赛作品', '往届参赛作品'],
		newentry: '新建参赛作品',
		production: [{
				title: '作品编号：',
				conten: '56777',
			},
			{
				title: '作品名称：',
				conten: '包装',
			},
			{
				title: '参赛赛道：',
				conten: '城市礼赞-自由组',
			},
			{
				title: '最后编辑：',
				conten: '2021.11.12  12:09:08',
			},
			{
				title: '作品作者：',
				conten: '作者1、作者2、作者3、作者4',
			}
		],
		presentArr: ['待提交', '已提交', '初评中', '入围复评', '复评中', '入围终评', '终评中', '终评结果确认中', '终评入围'],
		presentArrF: ['复评入围', '复评未入围'],
		presentTitle: '当前状态：',
		examine: '查看',
		delete: '删除',
		compile: '编辑',
		submit: '提交',
		supple: '补充作者信息',
		confirm: '确定',
		cancel: '取消',
		hind: '注：请及时提交，提交后才算上传成功'
	},
	logIn: {
		fillCode: '请填写邮箱验证码',
		twoPassword: '请再次填写密码',
		signAway: '立即注册',
		already: '已经有帐户， 直接登录',
		entryRequirements: '《参赛须知》',
		recommend: '《推荐须知》',
		forgetPassword: '忘记密码',
		getCode: '获取验证码',
		noGetCode: '秒后重新获取',
		toLogin: {
			fillEmail: '请填写注册邮箱',
			fillPassword: '请填写登录密码',
			registered: '注册账户',
			abbr: '立即登录',
		},
		forget: {
			fillEmail: '请填写注册邮箱',
			infuseText: '注：如果忘记密码，请填写注册邮箱并填写验证码后进行重置。',
			abbr: '重置密码',
		},
		setpassword: {
			title: '设置新密码',
			setPassword: '请设置密码',
			importPassword: '请设置新密码',
			infuse: '*至少8位，大写字母、小写字母、数字、特殊符号至少包含三种',
			abbr: '确定'
		},
		register: {
			category: ['设计师', '推荐人'],
			importPassword: '',
			fillEmail: '请填写登录邮箱',
			fillPassword: '请填写登录密码',
		}

	},
	foruploading: {
		step: ['填写作品信息', '上传作品资料', '提交参赛作品']
	},
	foot: {
		phone: "电话:0575-82878701",
		email: "邮箱:pcciccservice@rmwc.com.cn",
		postcode: "邮编:312300 ",
		ress: "地址：北京市朝阳区金台西路2号1号楼",
		title:'人民文创（北京）',
		title1:'人民文创国际创意大赛组委会',
		ress1: "地址:浙江省绍兴市上虞区曹娥街道复兴东路浙大网新科技城C2栋",
		InternetContent: "Copyright © 2021大赛网 浙ICP备2020036088号 "
	},
	personalCenter: {
		oldpassword: "旧密码",
		newpassword: "新密码",
		confirmpassword: "密码确认",
		oldpassword1: "请填写旧密码",
		newpassword1: "请填写新密码",
		confirmpassword1: "请再次填写新密码",
		change: '修改密码',
		names: "联系人姓名",
		type2_1: '公司全称',
		type2_2: '公司所在国家',
		type2_3: '公司所在地',
		type2_4: '公司详细地址',
		type2_5: '联系人姓名',
		type3_1: '机构全称',
		type4_1: '院校全称',
		type3_2: '机构所在国家',
		type4_2: '院校所在国家',
		type3_3: '机构所在地',
		type4_3: '院校所在地',
		type3_4: '机构详细地址',
		type4_4: '院校详细地址',
		photo: "点击上传",
		emailComments: "*注：您的注册邮箱即为您的登录帐号",
		changePassword: "修改密码",
		registerEmail: "注册邮箱：",
		accountNo: "账户号",
		basicInformation: "基本信息",
		basicInformation1:'（首次保存后无法修改）',
		selectThe: "请选择您的身份",
		personage: "个人",
		company: "公司",
		organization: "机构",
		academy: "院校",
		name: "姓名",
		surname: "姓",
		givenName: "名",
		nationality: "国籍",
		nationalitys: '请选择所在国家',
		yearBirth: "出生年份",
		secletYearBirth: "请选择出生年份",
		headPortrait: "头像",
		contactInformation: "联系信息",
		city: "所在城市",
		detailedAddres: "详细地址",
		fillDetailedAddres: "请填写详细地址",
		fillPhone: "请填写联系电话",
		phone: "联系电话",
		otherInformation: "其他信息",
		affiliated: "所属单位、组织或机构",
		fillAffiliated: "请填写所属单位、组织或机构",
		fillAffiliated1: "请填写公司全称",
		fillAffiliated2: "请填写机构全称",
		fillAffiliated3: "请填写院校全称",
		fillPosition: "请填写职位信息",
		position: "职位",
		freelanceNot: "是否为自由设计师",
		yes: "是",
		no: "否",
		serialNumber: "证书编号",
		contestName: '大赛名称',
		track: "赛道",
		titleEntry: "作品名称",
		winningTime: "获奖时间",
		awards: "奖项或入围阶段",
		author: "作者",
		download: "下载",
		invitedRecommend: "邀请推荐",
		recommendedRecord: "推荐记录",
		prizeWinning: "获奖情况",
		prizesName: "奖项名称",
		winningNumber: "获奖数量",
		winningTime: "获奖时间",
		getAmount: "获得金额",
		nameRecommender: "被推荐人",
		accountRegistrationTime: "账号注册时间",
		whetherUpload: "是否上传作品",
		recommendedRecordZhu: "注：被推荐人的作品入围及获奖情况请关注官方通知",
		approach: "了解本次比赛的途径",
		approach1: "往届参赛者",
		approach2: "他人推荐",
		approach3: "人民文创新媒体(公众号、微博等)",
		approach4: "线下广告(机场、杂志等广告栏)",
		approach5: "线下活动(路演、沙龙等)",
		approach6: "单位、院校或机构组织",
		approach7: "设计类网站或公众号",
		approach8: "其他",
		tishi: "请先完善账户信息，完成后即可进行作品上传",
		tishi1: "请先完善账户信息，完成后即可进行推荐",
		tishi2:'推荐人无法报名参赛',
		showTitle: "提示",
		showBtn: "确定",
		showBtn2: "修改账户信息",
		showBtn1: "保存",
		inviteHead01: "复制专属邀请链接",
		inviteHead02: "将链接发送给好友",
		inviteHead03: "好友通过链接注册上传作品",
		inviteHead04: "达到要求将获得相应组织奖",
		inviteExplain: "分享说明",
		inviteExplain1: "组织奖是为对人民文创国际创意大赛进行作品推荐且做出突出贡献者的奖励。奖励对象包括个人、企业、组织等，但不面向大赛工作人员及利益相关者发放。奖励发放须有效推荐不少于50位设计师参与大赛评选，且推荐人数最多的前10名。（请仔细阅读赛事规则，如被推荐者不符合要求，将取消该奖项的发放。）",
		inviteExplain2: "伯乐奖是为对人民文创国际创意大赛进行作品推荐且做出突出贡献者的奖励。奖励对象包括个人、企业、组织等，但不面向大赛工作人员及利益相关者发放。奖励发放标准为通过其推荐参加大赛的作品且此作品获得大赛各赛道第一名。（可与最佳组织奖重复领取）",
		inviteTitle1: "最佳组织奖10名 奖金",
		inviteTitle2: "伯乐奖5名 奖金",
		jiang: "奖状",
		jiang1: "(每名2万元)",
		jiang2: "(每名1万元)",
		inviteUrl: "邀请链接",
		copyUrl: "复制链接",
		reminder1: "身份不能为空",
		reminder2: "头像不能为空",
		reminder3: "出生年份不能为空",
		reminder4: "国籍不能为空",
		reminder5: "姓氏不能为空",
		reminder6: "所在城市不能为空",
		reminder7: "详细地址不能为空",
		reminder9: "联系电话不能为空",
		reminder10: "职位不能为空",
		reminder11: "请选择是否自由设计师",
		reminder12: "请选择了解途径",
		reminder13: "所属机构不能为空",
		nav1: "账户信息",
		nav2: "参赛作品",
		nav3: "证书下载",
		nav4: "我要推荐",
		nav5: "系统消息",
		nameType1: "设计师",
		nameType2: "推荐人"
	},
	productDisplay: {
		title: '产品展示',
		text: '展示了人民文创的文创产品',
		notes: "所有产品受版权保护，未经作者本人书面授权，禁止一切形式的转载及使用",
		time: "上架时间"
	},
	information: {
		title: '综合资讯',
		text: '展示了人民文创大赛有关资讯',
		source: "来源",
		time: "发布时间",
		journalist: "记者",
		compile: "编辑"
	},
	onlineDisplay: {
		title: '线上展示',
		text: '人民文创国际创意大赛历届获奖作品和优秀作品展示',
		notes: "所有产品受版权保护，未经作者本人书面授权，禁止一切形式的转载及使用",
		track: '赛道',
		author: "作者",
		introduce: "作品介绍",
		show: '作品展示',
		head1: "选择年份",
		head2: "选择赛道",
		head3: "选择奖项",
		head4: "选择入围情况"
	},
	expertSthink: {
		gengduo: '查看更多',
		title: '专家智库',
		text: '我们邀请了来自设计、文化、企业等领域的众多权威专家作为大赛评委，通过专业和多维度的视角为赛事的评审提供有力支持。',
	},
	addAuthor: {
		informationAuthor1: "第一作者信息",
		informationAuthor2: "第二作者信息",
		informationAuthor3: "第三作者信息",
		informationAuthor4: "第四作者信息",
		informationAuthor5: "第五作者信息",
		basicInformation: "基本信息",
		personalPhoto: "个人照片",
		name: "姓名",
		sex: "性别",
		nationality: "国籍",
		surname: "姓",
		names: "名",
		hintsex: "请选择性别",
		hintnationality: "请选择国籍",
		yearBirth: "出生年份",
		hintYearBirth: "请选择出生年份",
		idNumber: "身份证号",
		hintIdNumber: "请填入身份证号",
		phone: "联系电话",
		hintPhone: "请填入联系电话",
		city: "所在城市",
		hintcity: "请选择所在城市",
		ress: "详细地址",
		hintress: "请填入详细地址",
		affiliatedUnit: "所属单位、组织或机构",
		hintaffiliatedUnit: "请填入所属单位、组织或机构",
		position: "职位",
		hintposition: "请填入职位",
		studyExperience: "学习经历",
		occupationalHistory: "工作经历（选填）",
		hintschoolName: "请填入学校名称",
		schoolName: "学校名称",
		major: "专业",
		hintmajor: "例如：工业设计",
		runTime: "开始时间",
		hintrunTime: "请选择开始时间",
		stopTime: "结束时间",
		hintstopTime: "请选择结束时间",
		Awards: "获奖经历（选填）",
		contestName: "大赛名称",
		hintcontestName: "例如：第二届人民文创国际创意大赛",
		awards: "所获奖项",
		hintawards: "例如：金奖",
		awardsTime: "获奖时间",
		hintawardsTime: "请选择获奖时间",
		start_time: "请选择开始时间",
		start_times: "开始时间",
		end_time: "请选择结束时间",
		end_times: "结束时间",
		footBth2: "上一作者",
		footBth: "下一作者",
		footBth1: "提交",
		footBth3: "保存",
		organization: "单位名称",
		hintorganization: "请填写单位名称",
		duty: "职务",
		hintduty: "请填写职务",
		organizationphone: "单位联系方式",
		hintorganizationphone: "请填写单位联系方式",

	},
	workInformation: {
		serialNumber: "作品编号",
		name: "作品名称",
		track: "参赛赛道",
		time: "最后编辑",
		author: "参赛作者",
		essential: "基本信息",
		type: "作品分类",
		number: "作者人数",
		author1: "第一作者",
		author2: "第二作者",
		author3: "第三作者",
		author4: "第四作者",
		author5: "第五作者",
		carry: "作品获奖情况",
		awards: "所获奖项",
		names: "大赛名称",
		times: "获奖时间",
		describe: "作品描述",
		antistop: "作品关键词",
		material: "作品资料",
		explain: "作品说明图(第一张为主图)",
		vadio: "作品介绍视频",
		ppt: "作品说明文档",
		word: "作品建模文档",
		qingkuang0: "作品情况",
		qingkuang1: "作品上市销售情况",
		qingkuang2: "处于概念期",
		qingkuang3: "无上市销售经历和计划",
		qingkuang4: "有上市销售计划",
		qingkuang5: "正在上市销售",
		qingkuang6: "已停售",
		qingkuang7: "是否进行过版权登记",
		qingkuang8: "是",
		qingkuang9: "否",
	},
	forinformation: {
		basicsName: '基本信息',
		describe: '作品描述',
		keywordName: '作品关键词(1-3个)',
		keyName: '作品关键词',
		keyWordInput: '请填写作品关键词',
		custom: '自定义',
		dataName: '上传资料',
		condition: '作品情况',
		bearTitle: '作品获奖情况(选填)',
		data: {
			pictureTitle: '作品图片',
			pictureExplain: '请点击上传说明图',
			pictureHint: '请使用模板上传图片（4200*2970像素），每张图片大小1-5M，PNG格式。最多上传6张图片，其中至少包含1张作品主图',
			template: '下载模版',
			watch: '(最少1张，最多6张)',
			videoTitle: '作品介绍视频',
			videoExplain: '请点击上传介绍视频',
			videoHint: '视频时长在15~120s内，大小不超过200M，MP4格式',
			documentTitle: '作品说明文档',
			documentExplain: '请点击上传说明文档',
			documentHint: '文档大小不超过10M，PDF格式',
			modelingTitle: '作品3D建模文档',
			modelingExplain: '请点击上传建模文档',
			modelingHint: '文档大小不超过500M，fbx、dae、obj、stl等格式。如需提交更大的文件可通过邮件发送至组委会邮箱',
			marketName: '作品上市销售情况',
			marketArr: [{
				name: '处于概念期',
				id: 1
			}, {
				name: '无上市销售经历和计划',
				id: 2
			}, {
				name: '有上市销售计划',
				id: 3
			}, {
				name: '正在上市销售',
				id: 4
			}, {
				name: '已停售',
				id: 5
			}],
			copyrightName: '是否进行过版权登记',
			copyrightArr: [{
				name: '是',
				id: 1
			}, {
				name: '否',
				id: 0
			}],
			explain: '请上传版权登记照片',
			explaClick: '请点击上传',
			explaPmx: '*大小：不超过3M，PNG格式' //修改过
		},
		bear: {
			bearName: '大赛名称',
			bearX: '所获奖项',
			bearTime: '获奖时间',
			nameInput: '例如：第二届人民文创国际创意大赛',
			xInput: '例如：金奖',
			timeInput: '请选择时间',
		},
		basics: {
			authorName: '作品名称',
			authorInput: '请填写作品名称',
			take: '参赛赛道',
			takeInput: '请选择参赛赛道',
			classifyName: '作品分类',
			classifyInput: '请选择作品分类',
			classify: [],
			authorNum: '作者人数',
			authorNumInput: '请选择作者人数',
			authorArr: ['第一作者', '第二作者', '第三作者', '第四作者', '第五作者'],
			authorNameArr: '请填写作者姓名',
			describeConten: '在200字以内',
			describeRuls: '请填写作品描述',
			authorNumArr: [1, 2, 3, 4, 5],
			keywordinfuse: '注：关键词主要用于描述作品的风格、目标人群、作品应用场景等。如：国潮、白领女性、办公等',

		}
	},
	aboutContest: {
		nav: ['大赛介绍', '赛制规则', '常见问题', '资料下载', '联系方式'],
		dimensionality: [{
				icon: '',
				text: '安全性'
			},
			{
				icon: '',
				text: '合规性'
			},
			{
				icon: '',
				text: '易用性'
			},
			{
				icon: '',
				text: '完整性'
			},
			{
				icon: '',
				text: '创意性'
			},
			{
				icon: '',
				text: '美观性'
			},
			{
				icon: '',
				text: '实用性'
			},
			{
				icon: '',
				text: '可行性'
			},
			{
				icon: '',
				text: '文化内涵'
			},
			{
				icon: '',
				text: '商业价值'
			},
			{
				icon: '',
				text: '作品潜力'
			},
			{
				icon: '',
				text: '可持续性'
			},
			{
				icon: '',
				text: '切合主题'
			}
		]
	},
	inRegard: {
		Freedomgroup: '自由组',
		Namedgroups: '命题组',
		name: '名',
		rectX: '查看详情',
		qrCode: '微博二维码',
		official: '微信公众号',
		navList: ['关于大赛', '线上展览', '产品展示', '综合资讯', '专家智库', '报名参赛'], //缩写
		inRegardNav: [{
				title: '大赛介绍',
				url: 'gome0'
			},
			{
				title: '赛制规则',
				url: 'gome1'
			},
			{
				title: '常见问题',
				url: 'gome2'
			},
			{
				title: '资料下载',
				url: 'gome3'
			},
			{
				title: '联系方式',
				url: 'gome4'
			}
		], //缩写
		introduceTitle: '大赛介绍',
		introduce: '人民文创国际创意大赛由《环球人物》杂志社、人民文创（北京）文化发展有限公司主办、环球文创（浙江）文化发展有限公司承办的国际性文化创意类设计赛事。大赛通过充分发挥人民文创产业资源优势和《环球人物》的强大媒体宣传优势引导产业发展方向、整合产业渠道资源、打造产业交流平台、促进国际文创交融。并且增强创意设计师、企业等各方在文创产品的创作、生产和市场推广方面的体验，改善人民生活品质，提升大众审美水准。',
		competition: '大赛愿景',
		competitionConten: '“打造具有全球影响力的文创大赛品牌，汇聚和沉淀优质文创产业资源。”',
		exchange: '首届大赛——“美好生活 由我而创”',
		exchangeConten: '首届大赛由中国国际文化交流中心、中共绍兴市上虞区委、绍兴市上虞区政府特别支持。以“美好生活 由我而创”为主题进行全球范围的创意作品征集。共计收到23个国家和地区3269个机构及设计师的4255件参赛作品。并评选出了十五件优秀作品及11位人气设计师。累计获得超过1.1亿次曝光，受到社会的广泛关注和专业届的认可好评。',
		rebirth: '本届大赛——“数创新生”',
		theme: '(一)大赛主题',
		themeConten: '第二届人民文创国际创意大赛将以“数创新生”为主题，在遵循大赛愿景，延续办赛初心的基础上引入数字文创概念设计专属赛道，通过数字技术的利用助力创意设计发展，同时还将增加产业类作品的评选以使大赛作品的结构更加全面。',
		backdrop: '(二)大赛背景',
		backdropConten: '每个人都有着对美好生活的向往，伴随着社会经济、文化的发展，推进共同富裕幸福生活的实现成为大众的期盼，大赛将通过一些列的配套活动举办，将文化创意领入大众和乡村基层中去，助力人民群众的文化精神共同富足。另外通过对资源的优化重组，推动设计端、制造端、市场端的再融合再升级，将赛事平台变为广泛且有力的连接纽带。当今世界正经历百年未有之大变局，通过此次大赛希望引起大家对于未来生活新方式，文化发展新模式的关注和探索。',
		seeting: '(三)大赛设置',
		seetingConten: '大赛在延续首届经典赛制的基础上进行细化和拓展、共设置三条赛道五个组别。并且依旧秉持公平公正公开的办赛原则，邀请设计领域权威专家、知名制造企业领袖、文化市场/资本代表等共同组成大赛评审团。另外本届大赛除奖金外，还会推出一系列其他奖励和配套措施，为设计师提供一个自由发展的宝贵机遇。',
		framework: '(四)组织架构',
		frameworkConten: [{
				title: '主办单位',
				conten: '《环球人物》杂志社、人民文创（北京）文化发展有限公司'
			},
			{
				title: '特别支持单位',
				conten: '中国国际文化交流中心、中共绍兴市上虞区委、绍兴市上虞区人民政府'
			},
			{
				title: '承办单位',
				conten: '环球文创（浙江）文化发展有限公司'
			},
			{
				title: '协办单位',
				conten: '人民文娱、人民教育、腾讯'
			},
			{
				title: '支持单位',
				conten: '人民网、新华网'
			}
		],
		sort: ['01', '02', '03', '04'],
		regulationTitle: '赛制规则',
		regulationintroduceTitle: '赛道介绍',
		regulationConten: '本届大赛共设有三条赛道，分别为人民优创、城市礼赞、数字创意。其中，城市礼赞赛道分为自由组和命题组-“遇见上虞”，命题组下设城市IP形象设计、浙东唐诗之路和春晖文化3个主题，可围绕这些主题进行创意设计；数字创意赛道分为自由组和命题组-“文化&潮流”，命题组作品须以国潮为主题进行创意设计。',
		applyTitle: '报名时间及参赛要求',
		applyTimeTile: '(一)报名时间',
		timeConten: '大赛采取网上注册报名形式，报名时间为2021年11月30日12时—2022年2月28日24时（北京时间），报名地址为人民文创国际创意大赛官网www.rmwc.com.cn。',
		qualification: '(二)报名资格',
		qualificationToe: '大赛面向全球范围进行作品征集，凡从事文化、创意、设计行业的个人/团队（团队最多人数5人）、公司、组织、机构等均可参加。',
		qualificationwet: '大赛不接受往届人民文创国际创意大赛获奖作品的投稿。',
		costTitle: '(三)参赛费用',
		costToe: '大赛不收取任何形式的参赛费用。',
		costwet: '选手须自行承担终评阶段参评作品实物制作及寄送和回收的所有相关费用。',
		arrangeTitle: '日程安排',
		collect: {
			title: '作品征集',
			time: '2021.11.30—2022.3.31'
		},
		preliminary: {
			title: '初评',
			time: '2021.11.30—2022.3.31'
		},
		evaluation: {
			title: '复评',
			time: '2021.5.9—2022.5.22'
		},
		entity: {
			title: '实物作品递交',
			time: '2022.7.1—2022.7.5'
		},
		administrator: {
			title: '终评',
			time: '2022.7.8—2022.7.10'
		},
		respectfullyArr: [{
				title: '人民优创',
				conten: '通过对大众生活方式新变化的深入思考，发掘人民生活新需求，同时从主流价值观出发，创作设计有意义、有思想的文创产品。作品形式、风格不限。同时对于作品现已进入量产阶段或可在一年内面向市场进行销售，并具						备较为完整的设计创作、生产开发及推广方案的，将可获得产业类奖项的评选。如产品已上市，其时间不能超过一年。',
				// label: '风格不限/对于作品现已进入量产阶段或可在一年内面向市场进行销售/如产品已上市，其时间不能超过一年'
			},
			{
				title: '城市礼赞',
				conten: '围绕城市特色，提取城市人文、历史、社会、建筑等元素，通过将文化和创意的有机结合打造具有城市印记并可切实转化的品质城市礼品。作品风格不限，但须包含城市元素。',
				propositionTitle: '命题组-“遇见上虞”：',
				propositionArr: [{
						title: '主题1：城市IP形象设计',
						title2: '主题1：National & Ethnic Designs（东亚区域）',
						conten2: '以民族（国家）传统文化元素为核心，结合现代潮流元素进行设计创作。具体形式或类型参考数字创意自由组要求。',
						conten: '设计须能明确传达出上虞符号，且设计具有感染力，亲和力并适用于对应形式渠道的推广及传播。'
					},
					{
						title: '主题2：浙东唐诗之路',
						title2: '主题2：Culture & Diversity Designs（欧美区域）',
						conten2: '从多元性的角度对历史文化进行重新解读，结合数字展现的方式进行再创作。具体形式或类型参考数字创意自由组要求。',
						conten: '发现浙东唐诗之路之美，以文化创意角度进行全新解构及创作。'
					},
					{
						title: '主题3：春晖文化',
						conten: '围绕春晖文化进行设计创作并体现其文化内涵，同时结合当代潮流审美观进行融合延伸。',
					}
				]
			},

			{
				title: '数字创意',
				conten: '围绕数字及虚拟化产品展开设计创作，作品本身为数字形式，或通过数字技术、计算机技术等进行数字虚拟转化的创意作品。作品涵盖范围包括电子竞技、数字视觉艺术、AR、动漫游戏IP、数字收藏、虚拟交互等。具体类型包括但不限于各类数字插画、gif动图、虚拟角色皮肤服饰、虚拟道具、虚拟收藏、表情包、IP形象等。',
				propositionTitle: '命题组-“文化&潮流”：',
				propositionArr: [{
						title: '国潮（华语地区）',
						title2: '主题1：National & Ethnic Designs（东亚区域）',
						conten2: '以民族（国家）传统文化元素为核心，结合现代潮流元素进行设计创作。具体形式或类型参考数字创意自由组要求。',
						conten: '奖中国传统文化、非物质文化遗产等同当下时尚相融合，设计出具有时代潮流感的创意作品。具体形式或类型参考数字创意自由组要求。'
					},
					{
						title: '',
						title2: '主题2：Culture & Diversity Designs（欧美区域）',
						conten2: '从多元性的角度对历史文化进行重新解读，结合数字展现的方式进行再创作。具体形式或类型参考数字创意自由组要求。',
						conten: ''
					}
				]
			}
		],
		respectfully: {

		},
		awardsTe: {
			title: '奖项设置',
			titleLfe: '本届大赛共设置',
			awardsF: '个设计奖项',
			group: '组',
			arrTitle: ['人民优创赛道', '城市礼赞赛道', '数字创意赛道', '人民优创赛道'],

		},
		dimensionalityArr: [{
				icon: require('@/assets/megagame1.png'),
				text: '安全性',
				conten: '是否可确保目标用户在使用产品时身体及精神上不受到任何形式的侵害。'
			},
			{
				icon: require('@/assets/megagame2.png'),
				text: '合规性',
				conten: '作品设计是否符合国家法律法规或行业相关要求标准。'
			},
			{
				icon: require('@/assets/megagame3.png'),
				text: '易用性',
				conten: '产品是否可在目标用户不需进行大量学习的情况下快速掌握产品的使用方式并达到或超过预期目的。'
			},
			{
				icon: require('@/assets/megagame4.png'),
				text: '完整性',
				conten: '作品是否能够对产品用途、功能、组成元素进行完整清晰表现。'
			},
			{
				icon: require('@/assets/megagame5.png'),
				text: '创意性',
				conten: '作品是否设计新颖，并具有一定创新元素或创新功能。'
			},
			{
				icon: require('@/assets/megagame6.png'),
				text: '美观性',
				conten: '作品中所表现的产品是否符合社会主流审美观。'
			},
			{
				icon: require('@/assets/megagame7.png'),
				text: '实用性',
				conten: '作品是否和大众生活紧密相关，并具有实际使用价值。'
			},
			{
				icon: require('@/assets/megagame8.png'),
				text: '可行性',
				conten: '作品是否可进行工业化批量生产且具有一定投出产出比。'
			},
			{
				icon: require('@/assets/megagame9.png'),
				text: '文化内涵',
				conten: '作品设计是否包含文化元素或背景，体现文化传承及融汇。'
			},
			{
				icon: require('@/assets/megagame10.png'),
				text: '商业价值',
				conten: '作品投入市场后是否可被接受且产生一定经济收益。'
			},
			{
				icon: require('@/assets/megagame11.png'),
				text: '作品潜力',
				conten: '作品是否有较大的优化或改进提升价值。'
			},
			{
				icon: require('@/assets/megagame12.png'),
				text: '可持续性',
				conten: '产品是否可被长久使用，是否符合环保和节约理念。'
			},
			{
				icon: require('@/assets/megagame13.png'),
				text: '切合主题',
				conten: '产品设计是否与赛道或主题内容存在较高相关性。'
			}
		],
		awardsce: [{
				title: '金奖',
				money: '300,000元',
				icon: require('@/assets/j.png'),
				num: 1,
			},
			{
				title: '银奖',
				money: '100,000元',
				icon: require('@/assets/y.png'),
				num: 2,
			},
			{
				title: '铜奖',
				money: '20,000元',
				icon: require('@/assets/t.png'),
				num: 3,
			},
			{
				title: '最佳产业奖',
				money: '100,000元',
				icon: require('@/assets/q.png'),
				num: 1,
			},
			{
				title: '优质产业奖',
				money: '20,000元',
				icon: require('@/assets/q.png'),
				num: 2,
			},
			{
				title: '优秀奖',
				money: '5,000元',
				icon: require('@/assets/q.png'),
				num: 31,
			}
		],
		micro: [{
				title: '伯乐奖',
				money: '300,000元',
				icon: require('@/assets/q.png'),
				num: 5,
			},
			{
				title: '最佳组织奖',
				money: '100,000元',
				icon: require('@/assets/q.png'),
				num: 10,
			},
		],
		game: {
			freedom: [{
					title: '金奖',
					money: '200,000元',
					icon: require('@/assets/j.png'),
					num: 1,
				},
				{
					title: '银奖',
					money: '100,000元',
					icon: require('@/assets/y.png'),
					num: 2,
				},
				{
					title: '铜奖',
					money: '20,000元',
					icon: require('@/assets/t.png'),
					num: 3,
				},

				{
					title: '优秀奖',
					money: '5,000元',
					icon: require('@/assets/q.png'),
					num: 15,
				}
			],
			life: [{
					title: '最佳创作奖',
					money: '200,000元',
					icon: require('@/assets/q.png'),
					num: 1,
				},
				{
					title: '优质创作奖',
					money: '100,000元',
					icon: require('@/assets/q.png'),
					num: 2,
				},
				{
					title: '优秀奖',

					money: '20,000元',
					icon: require('@/assets/q.png'),
					num: 6,
				}
			]
		},
		creativity: {
			freedom: [{
					title: '金奖',
					money: '100,000元',
					icon: require('@/assets/j.png'),
					num: 1,
				},
				{
					title: '银奖',
					money: '50,000元',
					icon: require('@/assets/y.png'),
					num: 2,
				},
				{
					title: '铜奖',
					money: '10,000元',
					icon: require('@/assets/t.png'),
					num: 3,
				},

				{
					title: '优秀奖',
					money: '5,000元',
					icon: require('@/assets/q.png'),
					num: 15,
				}
			],
			life: [{
					title: '最佳创作奖',
					money: '100,000元',
					icon: require('@/assets/q.png'),
					num: 1,
				},
				{
					title: '优质创作奖',
					money: '50,000元',
					icon: require('@/assets/q.png'),
					num: 2,
				},
				{
					title: '优秀奖',
					money: '5,000元',
					icon: require('@/assets/q.png'),
					num: 6,
				}
			]
		},
		sheet: [{
				title: '主办单位',
				conten: '《环球人物》杂志社、人民文创（北京）文化发展有限公司'
			},
			{
				title: '特别支持单位',
				conten: '中国国际文化交流中心、中共绍兴市上虞区委、绍兴市上虞区人民政府'
			},
			{
				title: '承办单位',
				conten: '环球文创（浙江）文化发展有限公司'
			},
			{
				title: '协办单位',
				conten: '人民文娱、人民教育、腾讯'
			},
			{
				title: '支持单位',
				conten: '人民网、新华网'
			}
		],
		awards: [{
				title: '人民优创赛道',
				ranking: '奖项40名',
				money: '855,000',
				moneyName: '总奖金',
			},
			{
				title: '城市礼赞赛道',
				ranking: '奖项30名',
				money: '965,000',
				moneyName: '总奖金',
			},
			{
				title: '数字创意赛道',
				ranking: '奖项30名',
				money: '535,000',
				moneyName: '总奖金',
			},
			// {
			// 	title: '人民优创赛道',
			// 	ranking: '奖项40名',
			// 	money: '250,000',
			// 	moneyName: '总奖金',
			// }
		],
		DetailsIntroduced: '详情介绍',
		dimensionality: {
			tilte: '评审维度',
			conten: '大赛从设计、文化、创意等维度出发基于作品的全链路环节设计了完整的评价体系。在各评审环节将邀请相应领域的专家对该阶段选取的评审维度进行评判。'
		},
		submission: {
			tilte: '报名方式及作品提报要求',
			conten: [
				'/大赛报名须在人民文创国际创意大赛官网进行注册登录，登录后按照系统要求填写相关参赛者信息后即可生成参赛选手账户，后期所有作品上传、电子证书下载、评审信息查询等均通过此账户完成。',
				'/所有参赛者在进行注册报名时均须如实填报相关信息且不可重复注册参赛账户，如发现存在信息虚假或重复注册账号的，组委会将有权取消对应参赛者的参赛资格并封停账户',
				'/在参赛选手账户内，参赛者可进行作品上传提交。同一参赛者提交作品数量不限，但每件作品不可重复提交和同时申报多个赛道组别。对于重复提交的作品大赛组委会将会在进行去重后评审。',
				'/参赛者须对参赛作品拥有完整权属，如为组织或机构代为提报，须获得作品所有方的授权和确认。',
				'/参赛作品必须为设计者原创，不得抄袭或挪用他人作品。同时作品不得涉及色情、暴力、反动、种族歧视等违反法律法规和基本道德价值观的内容。',
				'/大赛组委会有权根据赛事规则及作品实际情况对参赛作品投报赛道组别进行调整。',
				'/所有参赛作品在报名阶段须按照系统要求提供完整的相关资料。在评审过程中组委会可能会视具体情况要求参赛者对作品资料进行再补充。',
				'/参赛作品提交资料分为必填和选填项目，选填项目可根据参赛者具体情况判断是否递交，但选填资料会在图片或文字介绍无法细致体现作品材质、结构、使用方式等情况下成为重要的评审依据，因此组委会强烈建议提交。提报所需资料具体要求详见参赛账户内作品新建页面。',
				'/ 参赛作品图片上传时须按照组委会提供的相关模板进行排版，不得随意设计变更。',
				'/ 大赛报名阶段仅需提交参赛作品电子材料。在终评评审阶段，其入围作品必须寄送实物或3D打样模型至大赛组委会，否则无法参与最终评审。作品实物或3D打样费用由参赛者自行承担。具体寄送时间及地址由组委会另行通知。',
				'/ 大赛报名阶段仅需提交参赛作品电子材料。在终评评审阶段，其入围作品必须寄送实物或3D打样模型至大赛组委会，否则无法参与最终评审。作品实物或3D打样费用由参赛者自行承担。具体寄送时间及地址由组委会另行通知。 如在资料填报和作品上传过程中存在疑问或无法上传、或上传后资料无法显示等问题的，可与大赛组委会进行联系确认，联系方式详见网站主页。'
			],
			query: '如在资料填报和作品上传过程中存在疑问或无法上传、或上传后资料无法显示等问题的，可与大赛组委会进行联系确认，联系方式详见网站主页。'
		},
		palm: {
			title: '获奖作品奖励发放',
			conten: [
				'/所有参赛并晋级入围作品大赛组委会将免费颁发电子版证书，证书在大赛最终奖项发布后可在官网参赛账户内自行下载。',
				'/大赛组委会将根据参赛作品所获不同奖项颁发相应实物证书或奖杯、奖金。实物证书及奖杯将于大赛颁奖典礼上进行颁发，如获奖者未能前往现场领奖则视为自动放弃实物证书及奖杯，组委会不提供邮寄或补发。',
				'/奖金的发放将根据中华人民共和国相关法律法规进行。参赛者所获奖金须依规缴纳20%的所得税，此部分税额将由大赛组委会直接代为缴纳，最终转账金额为扣除税款后的金额。',
				'/所有奖金均由大赛组委会通过银行转账形式进行发放。境内奖金接收账户可为获奖作品第一作者个人账户或报送方机构、公司/单位账户。境外奖金接收仅限个人账户。如奖金接收者为非作品第一作者个人或与报送方为不同机构、公司/单位、个人的，则需提交书面授权书并说明情况，同时由双方签字盖章确认',
				'/奖项颁发后如发现获奖作品存在抄袭、盗版等情况，参赛选手存在违法、违规等情况的，大赛组委会有权取消其所获奖项并收回证书、奖杯及奖金。',
				'/组织奖项如发现推荐人所推荐作品存在虚假充数、批量上传低劣作品、盗用他人名义等情况的一经核实立即取消其获奖资格，如情节特别恶劣的大赛组委会将追究其法律责任。'
			]
		},
		question: {
			title: '常见问题',
			conten: [{
					title: '问：参与大赛是否免费？',
					conten: '答：是的，人民文创国际创意大赛不收取任何报名费用。'
				},
				{
					title: '问：是否接受团队或单位合作的作品？',
					conten: '答：可以，个人、团队、单位组织皆可参赛。'
				},
				{
					title: '问：是否可以提交多件作品？',
					conten: '答：可以，个人、团队、单位组织都可提交多件作品。'
				},
				{
					title: '问：参加过其他比赛的获奖作品可以参加本次大赛吗?',
					conten: '答：可以，但是需要自身保有完整权属。'
				},
				{
					title: '问：不是中国的选手可以参赛吗?',
					conten: '答：可以，本次大赛属于国际性赛事，面向全球进行作品征集。'
				},
				{
					title: '问：各阶段的入围结果可以通过哪些渠道获知？',
					conten: '答：各阶段的入围结果可在对应评审阶段完成后进入报名系统内查看确认。'
				},
				{
					title: '问：什么时候需要进行作品打样？打样费用由谁承担？',
					conten: '答：在作品复评结束后，组委会将会安排终评入围选手进行作品实物准备。相关费用由参赛者自己承担。'
				},

				{
					title: '问：参与大赛各赛道可以使用同一注册账号吗？',
					conten: '答：可以使用同一账号在各个赛道上传作品，但需上传不同作品。'
				},
				{
					title: '问：个人信息或作品信息填写有错误，怎么在系统上进行修改？',
					conten: '答：在大赛报名时间结束前，均可在参赛账户内进行修改。'
				},
				{
					title: '问：作品图片或视频等信息上传失败该怎么处理？',
					conten: '答：请先检查文件格式及大小是否符合要求；刷新上传页面；尝试更换浏览器上传。若仍无法上传，请邮件咨询大赛组委会。'
				}
			]
		},
		download: {
			title: '资料下载',
			conten: [{
					title: '《大赛相关奖项名称及标识使用规则》',
					url: ''
				},
				{
					title: '《参赛须知》',
					url: ''
				},
				{
					title: '《推荐须知》',
					url: ''
				}
			]
		},
		relation: {
			title: '联系方式',
			conten: [{
					title: '电话：',
					ie: '0575-82878701'
				},
				{
					title: '地址：',
					ie: '浙江省绍兴市上虞区曹娥街道复兴东路浙大网新科技城C2栋'
				},
				{
					title: '邮编：',
					ie: '312300'
				},
				{
					title: '邮箱：',
					ie: 'pcciccservice@rmwc.com.cn'
				}
			]
		}
	},
	newWord:{
	   judgeTitle:'全部评委',
	   adver:'广告',
	   moreinform:'更多资讯',
	   peopleTile:'人民优创',
	   peopleralize:'发现生活新变化 ，设计生活新方式',
	   peopleConten:'发现生活新变化 ，设计生活新方式发现生活新变化 ，设计生活新方式发现生活新变化 ，设计生活新方式',
	   HopeTitle:'城市礼赞',
	   Hoperalize:'探寻城市历史底蕴，创作城市文化印象',
	   HopeConten:'探寻城市历史底蕴，创作城市文化印象探寻城市历史底蕴，创作城市文化印象',
	   figureTitle:'数字创意',
	   figureralize:'运用数字技术之力，开拓文化创意新疆域',
	   figureConten:'运用数字技术之力，开拓文化创意新疆域运用数字技术之力，开拓文化创意新疆域',
	   entryTitle1:'编辑状态：',
	   entryTitle2:'评审状态：',
	   entryTitle3:'评审结果：'
	},
	newText:{
		msg1:"作品信息",
		msg2:"作者信息",
		msg3:"作品情况",
		msg4:"作品上市销售情况",
		msg5:"产品介绍",
		msg6:"相关产品",
		msg7:"来源",
		msg8:"选择年份",
		msg9:"跳过",
		msg10:"请填写学校名称",
		msg11:"请填写学校专业",
		msg12:"请填写开始时间",
		msg13:"请填写结束时间",
		msg14:["男","女"],
		msg15:"请上传个人照片",
		msg16:"请输入姓名",
		msg17:"请选择性别",
		msg18:"请选择国籍",
		msg19:"请选择出生年份",
		msg20:"请输入身份证",
		msg21:"请输入联系电话",
		msg22:"请选择城市",
		msg23:"请输入详细地址",
		msg24:"请输入所属单位/机构",
		msg25:"请输入职位",
		msg26:"暂无可下载证书",
		msg27:"暂无新消息",
		msg28:"复制成功",
		msg29:"最佳组织奖",
		msg30:"最佳伯乐奖",
		msg31:"是", 
		msg32:"否",
		msg33:"密码格式错误",
		msg34:"请再次输入密码",
		msg35:"两次输入密码不一致",
		msg36:"旧密码不能为空",
		msg37:"密码不能为空",
		msg38:"密码最少8位",
		msg39:"修改密码成功",
		msg40:"保存成功",
		msg41:"暂无文档",
		msg42:"立即下载", 		
		msg43:"第二届大赛作品征集",
		msg44:"倒计时",
		msg45:"已截止",
		msg46:"提交成功",
		msg47:"作品征集时间已截止",
		msg48:["选择赛道","选择主题"],
		msg49:"请填写作品关键词",
		msg50:"上传资料",
		msg51:"资料下载",
		msg52:"所有作品图片上传均须使用大赛规定模板",
		msg53:"上传主图",
		msg54:"请先上传主图",
		msg55:"上传说明图",
		msg56:"您的浏览器不支持视频播放",
		msg57:"上传视频",
		msg58:"上传文档",
		msg59:"作品情况",
		msg60:"作品上市销售情况",
		msg61:"作品获奖情况（选填）",
		msg62:"保存成功",
		msg63:"提交成功",
		msg64:"注：初评开始前，您可前往账户的参赛作品页面中修改作品信息。",
		msg65:"继续补充作者信息",
		msg66:"返回参赛作品页面",
		msg67:"上一步",
		msg68:"保存",
		msg69:"下一步",
		msg70:"提交",
		msg71:"确定",
		msg72:"模板下载",
		msg73:"暂不下载",
		msg74:"立即下载",
		msg75:"年龄不能为空",
		msg76:"请输入数字值",
		msg77:"必须年满18岁",
		msg78:"作品上传",
		msg79:"消息通知",
		msg80:"全部消息",
		msg81:"个人中心",
		msg82:"退出登录",
		msg83:"邮箱不能为空",
		msg84:"请输入四位验证码",
		msg85:"发送成功",
		msg86:"邮箱格式错误",
		msg87:"广告",
		msg88:"全部资讯",
		msg89:"暂无数据",
		msg90:"注册即代表同意",
		msg91:'《参赛须知》',
		msg92:'《推荐须知》',
		msg101:'及',
		msg93:'注册成功',
		msg94:'请输入内容',
		msg95:'金奖',
		msg96:'银奖',
		msg97:'2021人民文创国际创意大赛',
		msg98:'关键词',
		msg99:'的搜索结果',
		msg100:'个',
		msg102:'身份证号',
		msg103:'请输入身份证号',
		msg104:'请输入密码',
		msg105:'保存成功',
		msg106:'请添加至少一张作品图片～',
		msg107:'请添加作品说明图～',
		msg108:'请选择作品人数～',
		// msg109:'请填写${i+1}作者姓名～',/
		msg110:'请添加至少一张作品图片～',
		msg111:'请选择是否进行版权登记～',
		msg112:'请上传版权登记照片～',
		msg113:'请填写作品关键词',
		msg114:'作品关键词不能超过3个',
		msg115:'作品关键词不能重复',
		msg116:'暂无参赛作品',
		msg117:'请选择省/市/区',
		msg118:'请上传正确的格式图片',
		msg119:'请上传PDF格式文件',
		msg120:'PDF文件不能超过10MB',
		msg121:'请上传JPEG格式图片',
		msg122:'图片大小不能超过10MB',
		msg123:'请上传PNG格式图片',
		msg124:'图片大小不能超过5MB',
		msg125:'图片大小不能超过3MB',
		msg126:'请上传正确的视频格式',
		msg127:'视频大小不能超过200MB',
		msg128:'请上传正确的pptx格式',
		msg129:'PPT大小不能超过10MB',
		msg130:'请上传正确的图片格式',
		msg131:'请上传正确的文档格式',
		msg132:'文档大小不可超过500M',
		msg133:'暂无数据',
		msg134:'请确认是否删除本作品?',
		msg135:'请勾选须知～',
		msg136:'zh'
	}
}
