import Vue from "vue";
import VueRouter from "vue-router";
import LogIn from './modules/login.js';
import Home from './modules/home.js';
Vue.use(VueRouter);
import {
	Message
} from 'element-ui'
const routes = [
	Home,
	LogIn,
	{
		path:"/agreement",
		name:"Agreement",
		component: () =>
			import( /* webpackChunkName: "about" */ "@/views/agreement.vue"),
	},
];
const router = new VueRouter({
	mode: "history",
	routes,
});
router.beforeEach((to, from, next) => {
	if(to.fullPath=='/login'){
		// next()
		// location.reload();
	}
	// next()
	window.scrollTo({
		'top':0,
	})
	if (to.meta.requireAuth) { // 判断该路由是否需要登录权限
		if (localStorage.getItem("token")) { // 判断本地是否存在token
			let EveryShow=localStorage.getItem('EveryShow')
			EveryShow=EveryShow=='true'?true:false;
			if(localStorage.getItem('dayNum')){
				if(localStorage.getItem('dayNum')==0&&to.fullPath=='/foruploading/forinformation'){
					Message.error({message:localStorage.getItem('languageSet')=='zh'?'报名参赛已截止':localStorage.getItem('languageSet')=='en'?'Entry is closed':'',offset:120})
					return
				}
			}
			//ka布达
			if(localStorage.getItem('EveryShow')){
				if(localStorage.getItem('EveryShow')=='true'&&to.fullPath=='/foruploading/forinformation'){
					Message.error({message:localStorage.getItem('languageSet')=='zh'?'请先完善账户信息':localStorage.getItem('languageSet')=='en'?'Please complete the account information first':'',offset:120})
					return
				}
			}
			//ka^
			if(to.fullPath=='/foruploading/forinformation'){
				if(localStorage.getItem('USERTYPE')==2){
					Message.error({message:localStorage.getItem('languageSet')=='zh'?'推荐人无法报名参赛':localStorage.getItem('languageSet')=='en'?'The recommender cannot sign up for the competition':'',offset:120})
					next({
						path:'/personalCenter'
					})
					return 
				}else{
					next()
				}
				// Message.error({message:$t('personalCenter.tishi'),offset:120})
			}else{
				next()
			}
		} else {
			// 未登录,跳转到登陆页面
			next({
				path: '/login'
			})
		}
	} else {
		next();
	}
	
	
});
export default router;

