const router = {
	path: "/",
	name: "index",
	redirect: {
		name: 'Home'
	},
	component: () => import( /* webpackChunkName: "about" */ "@/views/Home/index.vue"),
	children: [{
			path: "home",
			name: "Home",
			component: () =>
				import( /* webpackChunkName: "about" */ "@/views/Home/home.vue"),
		},
		{
			path:"aboutContest",
			name:"AboutContest",
			component: () =>
				import( /* webpackChunkName: "about" */ "@/views/Home/aboutContest.vue"),
		},
		{
			path: "entry",
			name: "Entry",
			component: () =>
				import( /* webpackChunkName: "about" */ "@/views/Home/entry.vue"),
		},
		{
			path: "foruploading",
			name: "ForUploading",
			redirect: {
				name: 'Forinformation'
			},
			meta:{
				requireAuth:true,
			},
			component: () =>
				import( /* webpackChunkName: "about" */ "@/views/Home/foruploading.vue"),
			children: [{
					path: "forinformation",
					name: "Forinformation",
					meta:{
						requireAuth:true,
					},
					component: () =>
						import( /* webpackChunkName: "about" */ "@/views/Home/forinformation.vue"),
				},
				{
					path: "forUpload",
					name: "ForUpload",
					meta:{
						requireAuth:true,
					},
					component: () =>
						import( /* webpackChunkName: "about" */ "@/views/Home/forUpload.vue"),
				}
			]
		}, ,
		{
			path: "onlineDisplay",
			name: "onlineDisplay",
			component: () =>
				import("@/views/Home/onlineDisplay/index.vue"),
		},
		{
			path: "onlineDisplayDetails",
			name: "onlineDisplayDetails",
			component: () =>
				import("@/views/Home/onlineDisplay/details.vue"),
		},
		{
			path: "personalCenter",
			name: "PersonalCenter",
			meta:{
				requireAuth:true,
			},
			component: () =>
				import("@/views/Home/personalCenter/index.vue"),
		},
		{
			path: "productDisplay",
			name: "ProductDisplay",
			component: () =>
				import("@/views/Home/productDisplay/index.vue"),
		},
		{
			path: "productDisplayDetails",
			name: "ProductDisplayDetails",
			component: () =>
				import("@/views/Home/productDisplay/details.vue"),
		},
		{
			path: "addAuthor",
			name: "AddAuthor",
			component: () =>
				import("@/views/Home/addAuthor/index.vue"),
		},
		{
			path: "expertSthink",
			name: "ExpertSthink",
			component: () =>
				import("@/views/Home/expertSthink/index.vue"),
		},
		{
			path:"information",
			name:"InFormation",
			component: () =>
				import("@/views/Home/information/index.vue"),
		},
		{
			path:"informationDetail",
			name:"InFormationDetail",
			component: () =>
				import("@/views/Home/information/detail.vue"),
		},
		{
			path:"workInformation",
			name:"workInformation",
			component: () =>
				import("@/views/Home/workInformation/index.vue"),
		},
		{
			path:"seach",
			name:"seach",
			component: () =>
				import("@/views/Home/seach.vue"),
		}
	]
}
export default router
