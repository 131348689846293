module.exports = {
	language: {
		name: '中文',
		list: [{
				title: ' About PCCICC ',
				to: '/aboutContest',
				name: 'AboutContest'
			},
			{
				title: ' Gallery ',
				to: '/onlineDisplay',
				name: 'onlineDisplay'
			},
			{
				title: ' Products ',
				to: '/productDisplay',
				name: 'ProductDisplay'
			},
			{
				title: ' Info ',
				to: '/information',
				name: 'InFormation'
			},
			{
				title: ' Experts ',
				to: '/expertSthink',
				name: 'ExpertSthink'
			},
			{
				title: ' Registration ',
				to: '/foruploading',
				name: 'Forinformation'
			}
		]
	},
	user: {
		login: ' Login ',
		register: ' Register ',
		loginUsername: 'Enter Email Address/Mobile Number',
	},
	home: {
		contestTitle: ' The 2nd People’s Cultural Creation International Creative Competition ',
		contestName: '“Digital Creation New Lifestyles”',
		ImmediateName: ' Join the Competition Now ',
		contestBrief: ' Overview of People’s Cultural Creation International Creative Competition ',
		brief: ' Building a cultural creative competition brand of global influence  Identifying and facilitating quality resources of the cultural creative sector ',
		knowaBout: ' Learn More ',
		trackName: ' About the Tracks of the Competition ',
		IntroductionBrief: ' About the Judges ',
		information: ' News & Information ',
		partner: ' Partners ',
		more: ' More Highlights ',
		wmore: ' View More '
	},
	entry: {
		serial: ' Works number：',
		category: [' Entry Works of This Competition ', ' Entry Works of Previous Competitions '],
		newentry: ' New Entry Works ',
		production: [{
				title: ' Work’s Number Assigned：',
				conten: '56777',
			},
			{
				title: ' Name of Work：',
				conten: ' Packaging ',
			},
			{
				title: ' Competition Track: ',
				conten: ' Praises for the City - Group for free theme entries ',
			},
			{
				title: ' Last Updated：',
				conten: '2021.11.12  12:09:08',
			},
			{
				title: ' Works and Authors：',
				conten: ' Author 1， Author 2， Author 3， Author 4',
			}
		],
		presentArr: [' To Be Submitted ', ' Submitted ', ' Under Preliminary Evaluation ',
			' Shortlisting for Re-evaluation ', ' Being Re-evaluated ', ' Shortlisted for Final Round ',
			' Under Final Round Evaluation ', ' Final Round Evaluation Result Being Confirmed ',
			' Final Round Winner '
		],
		presentArrF: [' Shortlisted for Re-evaluation ', ' Not Shortlisted for Re-evaluation '],
		presentTitle: ' Current Status：',
		examine: ' View ',
		delete: ' Delete ',
		compile: ' Edit ',
		submit: ' Submit ',
		supple: ' Add Author Info ',
		confirm: ' Enter ',
		cancel: ' Cancel ',
		hind: ' Note: Please submit in time. Upload is not successful before submission.'
	},
	logIn: {
		fillCode: ' Enter email confirmation code ',
		twoPassword: ' Re-enter new password ',
		signAway: ' Register now ',
		already: ' Existing Account， Log-in Now ',
		entryRequirements: ' Terms and Conditions for Entering Competition ',
		recommend: ' Terms and Conditions for Recommendation ',
		forgetPassword: ' Forgot Password ',
		getCode: ' Obtain Verification Code ',
		noGetCode: ' Request again after     seconds ',
		toLogin: {
			fillEmail: ' Please enter registered email address ',
			fillPassword: ' Enter Long-in Password ',
			registered: ' Register an account ',
			abbr: ' Log in now ',
		},
		forget: {
			fillEmail: ' Please enter registered email address ',
			infuseText: ' Note：If you have forgotten your password, please enter your registered email address, and enter the verification code received to reset ',
			abbr: ' Reset password ',
		},
		setpassword: {
			title: ' Create new password ',
			setPassword: ' Please create password ',
			importPassword: ' Please create new password ',
			infuse: '* Eight digits at the minimum, including at least three categories from the following: capital letters, lower-case letters, numerals, special symbols ',
			abbr: ' Enter '
		},
		register: {
			category: [' Designer ', ' Reference '],
			importPassword: '',
			fillEmail: ' Please enter log-in email address ',
			fillPassword: ' Please enter log-in password ',
		}

	},
	foruploading: {
		step: [' Enter information of the works ', ' upload documents about the works ', ' submit entry works ']
	},
	foot: {
		phone: "0575-82878701",
		email: "email: pcciccservice@rmwc.com.cn",
		postcode: 312300,
		ress: "Address: Building 1, 2 Jintai West Road, Chaoyang District, Beijing",
		title: ' People is Cultural Innovation(Beijing)',
		title1: 'Organizing Committee of The People is International Cultural and Creative Competition',
		ress1: "Address: Building C2, East Fuxing Road, Cao e Street, Shangyu District, Shaoxing city, Zhejiang Province",
		InternetContent: 'Copyright© 2021 InternetContent'
	},
	personalCenter: {
		oldpassword: " Old Password ",
		newpassword: " New Password ",
		confirmpassword: " Confirm Password ",
		oldpassword1: " Please type in old password ",
		newpassword1: " Please type in new password ",
		confirmpassword1: " Please type in new password again ",
		change: ' Revise your password ',
		names: " Contact Person’s Name ",
		type2_1: ' Full name of company ',
		type2_2: ' Country of company ',
		type2_3: ' City of company ',
		type2_4: ' Company full address ',
		type2_5: ' Name of contact person ',
		type3_1: ' Full name of institution ',
		type4_1: ' Full name of school ',
		type3_2: ' Country of institution ',
		type4_2: ' Country of school ',
		type3_3: ' City of Institution ',
		type4_3: ' City of school ',
		type3_4: ' Institution’s full address ',
		type4_4: ' School’s full address ',
		photo: "upload ",
		emailComments: "* Note: your registered email address is your log-in account name ",
		changePassword: " Revise your password ",
		registerEmail: " Registered email account：",
		accountNo: " Account number ",
		basicInformation: " Basic information",
		basicInformation1: "（It cannot be modified after being saved for the first time）",
		selectThe: " Select your identity ",
		personage: " Individual ",
		company: " Company ",
		organization: " institution ",
		academy: " School ",
		name: " Full Name ",
		surname: " Surname ",
		givenName: " Given Name ",
		nationality: " Nationality ",
		nationalitys: ' Please select country ',
		yearBirth: " Year of Birth ",
		secletYearBirth: " Please select year of birth ",
		headPortrait: " Headshot ",
		contactInformation: " Contact Information ",
		city: " City of Residence ",
		detailedAddres: " Detailed Address ",
		fillDetailedAddres: " Please enter detailed address ",
		fillPhone: " Please enter telephone number ",
		phone: " Telephone number ",
		otherInformation: " Other Information ",
		affiliated: " Work Unit and organization",
		fillAffiliated: " Please enter the work unit, organization or institute you are affiliated to ",
		fillAffiliated1: " Please type in full name of company ",
		fillAffiliated2: " Please type in full name of institution ",
		fillAffiliated3: " Please type in full name of school ",
		fillPosition: " Enter information of your position ",
		position: " Position ",
		freelanceNot: " Are you a freelance designer?",
		yes: " Yes ",
		no: " No ",
		serialNumber: " Certificate No.",
		contestName: ' Name of Competition ',
		track: " Track ",
		titleEntry: " Name of work ",
		winningTime: " Time of award ",
		awards: " Award type or phase of shortlisting ",
		author: " Author ",
		download: " Download ",
		invitedRecommend: " Invitation and Recommendation ",
		recommendedRecord: " Record of recommendations ",
		prizeWinning: " Prizes won ",
		prizesName: " Name of prize ",
		winningNumber: " Number of prizes ",
		winningTime: " Time of winning prizes ",
		getAmount: " Prize money awarded ",
		nameRecommender: " Name of referee ",
		accountRegistrationTime: " Time of account registration ",
		whetherUpload: " Whether I wish to upload works ",
		recommendedRecordZhu: " Please see official bulletins for the shortlisting and award winning information of the recommended works ",
		approach: "Understand the way of competition",
		approach1: " Contestants of past competitions ",
		approach2: " Recommended by others ",
		approach3: " New Media (WeChat Public Account, Weibo, etc.)People’s Cultural Creation ",
		approach4: " Offline ad (airport，notes etc. Advertising column)",
		approach5: " Offline events (roadshows, saloons, etc.)",
		approach6: " Work unit, school, institute or organization ",
		approach7: " Websites or WeChat public accounts dedicated to designing ",
		approach8: " Other ",
		tishi: " Note:please enter all account information required, and you then can upload entry works ",
		tishi1: " Note:please enter all account information required, and you then can recommend  ",
		tishi2:'The recommender cannot sign up for the competition',
		showTitle: " Prompt ",
		showBtn: " Enter ",
		showBtn2: " Revise account information ",
		showBtn1: " Save ",
		inviteHead01: " Copy your exclusive invitation link ",
		inviteHead02: " Send link to a contact ",
		inviteHead03: " The contact to register and upload entries via links ",
		inviteHead04: " The mobilizer prize is awarded when requirements are met ",
		inviteExplain: " Explanations for the sharing ",
		inviteExplain1: " The Mobilizer Prize is awarded to those who have made outstanding contribution by recommending works to the People’s Cultural Creation International Creative Competition. Winners can be individuals, enterprises, and organizations, but Competition staff and its stakeholders shall not be eligible. A winner shall have made valid recommendations of 50 designers to participate in the Competition at a minimum, ranking among the top 10 in terms of their number of recommendations. (Please read the Competition rules and regulations carefully. If the recommendations do not meet the requirements, then the candidate shall be disqualified.)",
		inviteExplain2: " The “Talent Spotter” prize is awarded to those who have made great contribution by recommending works to the People’s Cultural Creation International Creative Competition. A prize winner can be an individual, an enterprise, or an organization, but Competition staff and stakeholders shall not be eligible. The criterion for the prize is that the entry work recommended to the Competition by the candidate in question has won the first prize in the corresponding competition track. (A candidate to this prize is also eligible for the Mobilizer Prize at the same time.)",
		inviteTitle1: " Best Mobilizer Prizes, 10  Prize Money ",
		inviteTitle2: " Talent Spotter Prizes, 5 Prize Money ",
		jiang: " Certificate of Prize ",
		jiang1: "(20,000 RMB yuan each)",
		jiang2: "(10,000 RMB yuan each)",
		inviteUrl: " Invitation Link ",
		copyUrl: " Copy Link ",
		reminder1: " Identity cannot be blank ",
		reminder2: " Profile photo cannot be blank ",
		reminder3: " Year of birth cannot be blank ",
		reminder4: " Nationality cannot be blank ",
		reminder5: " Surname cannot be blank ",
		reminder6: " City cannot be blank ",
		reminder7: " Detailed address cannot be blank ",
		reminder9: " Contact number cannot be blank ",
		reminder10: " Work title cannot be blank ",
		reminder11: " Please select whether you are a freelance designer ",
		reminder12: " Please select how you learned of the Competition ",
		reminder13: " Employing institution cannot be blank ",
		nav1: " Account information ",
		nav2: " Entry works ",
		nav3: " Certificate download ",
		nav4: " I want to recommend ",
		nav5: " System message ",
		nameType1: " Designer ",
		nameType2: " Reference "
	},
	productDisplay: {
		title: ' Products Display ',
		text: ' Displaying the cultural creative products of People Cultural Creation ',
		notes: " All products are copyright-protected. Reproductions and uses of any form are prohibited except with the author’s written authorizations.",
		time: " Time of Display "
	},
	information: {
		title: ' News & Information ',
		text: ' Displaying is the relevant formation about the Competition sponsored by the People’s Cultural Creation ',
		source: " Source ",
		time: " Time of release ",
		journalist: " Correspondent ",
		compile: " Editor "
	},
	onlineDisplay: {
		title: ' Online Exhibition ',
		text: ' Exhibition of the Award-winning and Outstanding Works of People’s Cultural Creation International Creative Competitions ',
		notes: " All products are copyright-protected. Reproductions and uses of any form are prohibited except with the author’s written authorizations.",
		track: ' Competition tracks ',
		author: " Authors ",
		introduce: " About the works ",
		show: ' Display of entry works ',
		head1: " Select year ",
		head2: " Select competition track ",
		head3: " Select category of prize ",
		head4: " Select shortlisting status "
	},
	expertSthink: {
		gengduo: ' View more ',
		title: ' Experts and think-tanks ',
		text: ' We have invited a host of authority figures and experts from design, culture and corporate sectors as judges of the Competition, who will provide strong support for the review and evaluation in the competition event from professional and pluralistic perspectives.',
	},
	addAuthor: {
		informationAuthor1: " First author information ",
		informationAuthor2: " Second author information ",
		informationAuthor3: " Third author information ",
		informationAuthor4: " Fourth author information ",
		informationAuthor5: " Fifth author information ",
		basicInformation: " Basic information ",
		personalPhoto: " Personal photograph ",
		name: " Full name ",
		sex: " Gender ",
		nationality: " Nationality ",
		surname: " Surname ",
		names: " Given name ",
		hintsex: " Please select gender ",
		hintnationality: " Please select nationality ",
		yearBirth: " Year of birth ",
		hintYearBirth: " Please select year of birth ",
		idNumber: " Number of identification Card ",
		hintIdNumber: " Please type in number of identification card ",
		phone: " Contact telephone number ",
		hintPhone: " Please type in contact telephone number ",
		city: " City of residence ",
		hintcity: " Please select city of residence ",
		ress: " Detailed address ",
		hintress: " Please fill in detailed address ",
		affiliatedUnit: " Affiliation ",
		hintaffiliatedUnit: " Please type in affiliation ",
		position: " Work title ",
		hintposition: " Please type in work title ",
		studyExperience: " Educational experience ",
		occupationalHistory: " Work experiences (optional)",
		hintschoolName: " Please type in name of school ",
		schoolName: " Name of school ",
		major: " Major ",
		hintmajor: " For example, industrial design ",
		runTime: " Start time ",
		hintrunTime: " Please select start time ",
		stopTime: " Finish time ",
		hintstopTime: " Please select finish time ",
		Awards: " Prize-winning experiences (optional)",
		contestName: " Name of Competition ",
		hintcontestName: " For example, “The 2nd People’s Cultural Creation International Creative Competition”",
		awards: " Name of award category ",
		hintawards: " For example, gold prize ",
		awardsTime: " Time of award ",
		hintawardsTime: " Please select time of award ",
		start_time: " Please select start time ",
		start_times: " Start time ",
		end_time: " Please select finish time ",
		end_times: " Finish time ",
		footBth2: " Previous author ",
		footBth: " Next author ",
		footBth1: " Submit ",
		footBth3: " Save ",
		organization: " Name of employing organization ",
		hintorganization: " Please type in name of employing organization ",
		duty: " Work title ",
		hintduty: " Please type in work title ",
		organizationphone: " Contact information of employing organization ",
		hintorganizationphone: " Please type in contact information of employing organization ",

	},
	workInformation: {
		serialNumber: " Serial number of the work ",
		name: " Name of work ",
		track: " Competition Track ",
		time: " Last edited ",
		author: " Author to enter the competition ",
		essential: " Basic information ",
		type: " Category of the works ",
		number: " Number of authors ",
		author1: " First author ",
		author2: " Second author ",
		author3: " Third author ",
		author4: " Fourth author ",
		author5: " Fifth author ",
		carry: " Awards of the work ",
		awards: " Category of award of the work ",
		names: " Name of the contest ",
		times: " Time of award obtained ",
		describe: " Description of the work ",
		antistop: " Keywords of the work ",
		material: " Data of the work ",
		explain: " Descriptive sketch of the work (the first being the major sketch)",
		vadio: " Video about the work ",
		ppt: " File about the work ",
		word: " File for modeling the work ",
		qingkuang0: " About the work ",
		qingkuang1: " Marketing and sale of the work ",
		qingkuang2: " In the conception phase ",
		qingkuang3: " no experience or plan for marketing and sales ",
		qingkuang4: " having marketing and sale plans ",
		qingkuang5: " being marketed and on sale ",
		qingkuang6: " having stopped sales ",
		qingkuang7: " Having registered the copyright?",
		qingkuang8: " Yes ",
		qingkuang9: " No ",
	},
	forinformation: {
		basicsName: ' Basic information ',
		describe: ' Description of the work ',
		keywordName: ' Keywords of the work (1-3)',
		keyName: ' Keywords of the work ',
		keyWordInput: ' Please enter keywords of the work ',
		custom: ' Please specify ',
		dataName: ' Upload data ',
		condition: ' About the work ',
		bearTitle: ' Awards for the work (optional)',
		data: {
			pictureTitle: ' Photograph of the work ',
			pictureExplain: ' Please click to upload descriptive sketch ',
			pictureHint: ' Please use template to upload picture (4200×2970 pixles), each picture should be 1-5 megabytes, in the PNG file format. A maximum of six pictures can be uploaded, including one premium picture of the entry work at a minimum.',
			template: ' Template for downloading ',
			watch: '(One photograph at the minimum, six at the maximum)',
			videoTitle: ' Video about the work ',
			videoExplain: ' Please click to upload the video about the work ',
			videoHint: ' Size: overall duration 15 seconds -120 seconds, no larger than 200 megabytes, in the MP4 format ',
			documentTitle: ' File about the work ',
			documentExplain: ' Please click to upload the file about the work ',
			documentHint: ' Document size no larger than 10 megabytes, in the PDF file format ',
			modelingTitle: '3D modeling Document of the work ',
			modelingExplain: ' Please click to upload file for modeling the work ',
			modelingHint: ' Document size no larger than 500 megabytes, in such file formats as FBX, DAE, OBJ and ST1. Should you need to submit larger documents, please send an email to the Organizing Committee’s email account.',
			marketName: ' Marketing and sale of the work ',
			marketArr: [{
				name: ' In the conception phase ',
				id: 1
			}, {
				name: ' no experience or plan for marketing and sales ',
				id: 2
			}, {
				name: ' having marketing and sale plans ',
				id: 3
			}, {
				name: ' being marketed and on sale ',
				id: 4
			}, {
				name: ' having stopped sales ',
				id: 5
			}],
			copyrightName: ' Having registered the copyright?',
			copyrightArr: [{
				name: ' Yes ',
				id: 1
			}, {
				name: ' No ',
				id: 0
			}],
			explain: ' Please upload picture of copyright registration ',
			explaClick: ' Please click to upload ',
			explaPmx: '* Size: no larger than 3 megabytes, in PNG format ' //修改过
		},
		bear: {
			bearName: ' Name of the contest ',
			bearX: ' Award received ',
			bearTime: ' Time of award ',
			nameInput: ' e.g. The 2nd People’s Cultural Creation International Creative Competition ',
			xInput: ' e.g. Gold Award ',
			timeInput: ' Please select time ',
		},
		basics: {
			authorName: ' Name of work ',
			authorInput: ' Please enter the name of the work ',
			take: ' Competition track ',
			takeInput: ' Please select the competition track ',
			classifyName: ' Categories of the works ',
			classifyInput: ' Please select category of the work ',
			classify: [],
			authorNum: ' Number of authors ',
			authorNumInput: ' Please select number of authors ',
			authorArr: [' First author ', ' Second author ', ' Third author ', ' Fourth author ', ' Fifth author '],
			authorNameArr: ' Please enter author’s full name ',
			describeConten: ' Within 400 English characters ',
			describeRuls: ' Please enter descriptions of the work ',
			authorNumArr: [1, 2, 3, 4, 5],
			keywordinfuse: ' Note：Keywords are mainly used for describing the styles, targeted audience, and application scenarios of the works.',

		}
	},
	aboutContest: {
		nav: [' About PCCICC ', ' Competition Rules ', ' Frequently Asked Questions ', ' Download ',
			' Contact Information'
		],
		dimensionality: [{
				icon: '',
				text: ' Safety '
			},
			{
				icon: '',
				text: ' Compliance '
			},
			{
				icon: '',
				text: ' Accessibility '
			},
			{
				icon: '',
				text: ' Integrity '
			},
			{
				icon: '',
				text: ' Innovativeness '
			},
			{
				icon: '',
				text: ' Beauty '
			},
			{
				icon: '',
				text: ' Practicality '
			},
			{
				icon: '',
				text: ' Feasibility '
			},
			{
				icon: '',
				text: ' Cultural content '
			},
			{
				icon: '',
				text: ' Commercial value '
			},
			{
				icon: '',
				text: ' Potentiality of the work '
			},
			{
				icon: '',
				text: ' Sustainability '
			},
			{
				icon: '',
				text: ' Thematic relevance '
			}
		]
	},
	inRegard: {
		Freedomgroup: ' Free Themes Group ',
		Namedgroups: ' Designated Theme Group ',
		name: 'Name',
		rectX: ' View more ',
		qrCode: ' Weibo QR Code ',
		official: ' WeChat Public Account QR Code ',
		navList: [' About PCCICC ', ' Gallery ', ' Products ', ' Info ', ' Experts ', ' Registration '], //缩写
		inRegardNav: [{
				title: ' About PCCICC ',
				url: 'gome0'
			},
			{
				title: ' Competition Rules ',
				url: 'gome1'
			},
			{
				title: ' Frequently Asked Questions ',
				url: 'gome2'
			},
			{
				title: ' Download ',
				url: 'gome3'
			},
			{
				title: ' Contact Information',
				url: 'gome4'
			}
		], //缩写
		introduceTitle: ' About PCCICC ',
		introduce: ' People’s Cultural Creation International Creative Competition is sponsored by the Global People magazine and People’s Cultural Creation (Beijing) Cultural Development Company, organized by Global Cultural Creation (Zhejiang) Cultural Development Company Ltd., with the vision of “Building a cultural creative competition brand of global influence, Identifying and facilitating quality resources of the cultural creative sector”. Meanwhile, by giving full play to People’s Cultural Creation’s advantages in terms of the resources in the sector, and Global People’s powerful publicity and promotion, the Competition leads the development direction of the industry, integrates the sector’s channels and resources, builds an exchange platform of the sector, and facilitates the interactions and integration of the international cultural creative sector. It also enhances the experiences of creative designers and businesses in the conception, production, marketing and promotions of cultural creative products, improves the people’s living quality, and elevates the popular aesthetic tastes. ',
		competition: ' Visions ',
		competitionConten: '“Building a cultural creative competition brand of global influence Identifying and facilitating quality resources of the cultural creative sector”',
		exchange: ' The First Competition——“Better life,Best creators”',
		exchangeConten: ' The first Competition had the special support from China International Culture Exchange Center, CCP Committee of Shangyu District of Shaoxing Municipality, and the Shangyu District Government of the Shaoxing Municipality. It called for creative works from around the globe under the theme of “Beautiful Life My Creation” and received 4,255 entry works of 3,269 institutes and designers from 23 countries and regions. 15 outstanding works and 11 well-received designers won awards. Accumulatively the competition was given 110 million times of attention, and was widely noted in the society and highly acclaimed in the sector.',
		rebirth: ' This Competition——“Digital Creation New Lifestyles”',
		theme: '(I) Theme ',
		themeConten: ' The 2nd People’s Cultural Creation International Creative Competition is themed on “Digital Creation New Lifestyles”. While following the vision of the Competition and continuing the original aspirations of the Competition, a specifically designated competition track of concepts and designs digital cultural creation is added, where the use of digital technologies facilitates the development of creative designs. Meanwhile, awards for industrial works shall be introduced so that the Competition boasts of a more comprehensive structure of entry works. ',
		backdrop: '(II) Background ',
		backdropConten: ' Through optimization and regrouping of resources, a re-integration and re-upgrading of the design, manufacturing and market ends are promoted, so that the Competition platform also acts as the extensive and powerful ties of connection. Today’s world is undergoing a great transformation unprecedented in the last hundred years. It is hoped that by this Competition, attention to, and exploration of, the new lifestyles of the future and the new patterns of cultural development are to be encouraged.',
		seeting: '(III) Tracks ',
		seetingConten: ' Well-crafted rules adopted for the first Competition are kept for this Competition. On this basis, the rules are further detailed and expanded, so that three tracks of five groupings are provided. On competition principles of fairness, justice and openness, authoritative experts, leaders of renowned manufacturing businesses, and cultural market/capital representatives in the design sector have been invited to form the Board of Judges. Apart from prize money to be awarded, this Competition shall also roll out a range of other incentives and support measures, in order to provide a valuable opportunity of free development for designers.',
		framework: '(IV) Organizational Structure ',
		frameworkConten: [{
				title: ' Sponsor ',
				conten: ' Global People magazine，People’s Cultural Creation (Beijing) Cultural Development Company '
			},
			{
				title: ' Specially Support By ',
				conten: ' China International Culture Exchange Center, CCP Committee of Shangyu District of Shaoxing Municipality, Shangyu District Government of the Shaoxing Municipality '
			},
			{
				title: ' Organizer, Co-organizer ',
				conten: ' Global People Cultural Creation (Zhejiang) Cultural Development Company Ltd '
			},
			{
				title: ' Co-organizer ',
				conten: ' People’s Joy Works, People’s Education, Tencent '
			},
			{
				title: ' Supported By ',
				conten: ' People.cn, News.cn '
			}
		],
		sort: ['01', '02', '03', '04'],
		regulationTitle: ' Competition Rules ',
		regulationintroduceTitle: ' Competition Tracks ',
		regulationConten: ' This Competition designates three tracks: People’s Premium Creation, Praises for the City, and Digital Creation. Praises for the City is composed of Group for Free Themes and Group of Designated Themes – “Surprise Encounter with Shangyu”. The latter includes three sub-themes: Urban IP Image Design, Road of Tang Poetry of Eastern Zhejiang, and Culture of Parental Love, and creative designs around these sub-themes are welcome. The Digital Creation track is divided into the Group for Free Themes and the Group of Designated Themes – “Culture & Trends”, entries of the latter should have been creatively designed under the theme of China Chic.',
		applyTitle: ' Registration Dates and Entry Requirements',
		applyTimeTile: '(I) Time of Registration ',
		timeConten: ' This Competition provides online registration, open between 12 o’clock on November 30, 2021 and 24 o’clock on February 28, 2022 (Beijing Time), at the official website of the People’s Cultural Creation International Creative Competition: www.rmwc.com.cn. ',
		qualification: ' (II) Qualifications for registration ',
		qualificationToe: '1 This Competition calls for entry works around the globe, all individuals/teams (of 5 members maximally), enterprises, organizations and institutions that are engaged in cultural, creative and design sectors are all eligible to enter the Competition.',
		qualificationwet: '2 This Competition does not accept entry works that won awards in the previous People’s Cultural Creation International Creative Competition.',
		costTitle: '(III) Entry Fees ',
		costToe: '1 The Competition does not charge entry fees in any form.',
		costwet: '2 Contestants shall undertake the expenses incurred for the production, post and recovery of the physical entry works in the phase of final evaluation and assessment.',
		arrangeTitle: ' Itinerary of the Competition ',
		collect: {
			title: ' Call for Entry Works ',
			time: '2021.11.30—2022.3.31'
		},
		preliminary: {
			title: ' Preliminary Round of Review and Evaluation ',
			time: '2021.11.30—2022.3.31'
		},
		evaluation: {
			title: ' Second Round of Review and Evaluation ',
			time: '2021.5.9—2022.5.22'
		},
		entity: {
			title: ' Submission of Entry Works in the Physical Form ',
			time: '2022.7.1—2022.7.5'
		},
		administrator: {
			title: ' Final Review and Evaluation ',
			time: '2022.7.8—2022.7.10'
		},
		respectfullyArr: [{
				title: ' People’s Premium Creation ',
				conten: ' Based on mainstream values, meaningful and thought-provoking cultural creation works are created and designed, through profound reflections on the new changes in people’s lives and an investigation of the new demands in their lives. They may include but are not limited to stationery, gifts, accessories, watches and clocks, lights and fittings, and household articles. There is no constraint on the specific formats and styles of the entry works. For the entry works whose manufacture has been launched or which can be on the market for sale in a year’s time, especially those eligible for prizes in respective industries and having complete plans for designs, creations, productions, development and marketing, they can also be reviewed and evaluated for the awards. Their time on the market for sale can only be no longer than a year to be eligible for the Competition.',
				// label: '风格不限/对于作品现已进入量产阶段或可在一年内面向市场进行销售/如产品已上市，其时间不能超过一年'
			},
			{
				title: ' Praises for the City ',
				conten: ' By focusing on urban characteristics, extracting humanistic, historical, social and architectural elements, and integrating culture with creation organically, quality urban gifts that bear urban characteristics and that can be substantially transformed are to be crafted. There is no constraint as to the city or the style and manner of the entry work, but themed urban elements have to be included.',
				propositionTitle: ' Group of Designated Themes – “Surprise Encounter with Shangyu”：',
				propositionArr: [{
						title: ' Theme 1: Urban IP Image Design ',
						title2: ' National & Ethnic Designs（East Asia and Southeast Asia）',
						conten2: ' Design and production must be undertaken with a focus on ethnic (national) traditi8onal cultural elements, in integration with modern fashion and tread elements. For specific format or category, please refer to the requirements for the Group for Free Theme Entries.',
						conten: ' The design has to convey the city symbols of Shangyu, be equipped with a contagiousness and an emotional affinity, and be applicable to the promotion and transmission in corresponding channels.'
					},
					{
						title: ' Theme 2: Road of Tang Poetry of Eastern Zhejiang ',
						title2: 'Culture & Diversity Designs（Europe and the Americas）',
						conten2: ' A reinterpretation of history and culture shall be carried out from a pluralistic perspective, and re-creation shall be performed in integration with digital representations. For specific format or category, please refer to the requirements for the Group for Free Theme Entries.',
						conten: ' The beauty of Tang Poetry of eastern Zhejiang Province is to be discovered, and a brand-new deconstruction and production are to be performed from a culturally creative perspective. No specific format or style of the entry work is designated.'
					},
					{
						title: ' Theme 3: Culture of Parental Love ',
						conten: ' The culture of parental love shall be a central theme in the design and creation, and integration and extension can be carried out in integration with the contemporary trends and aesthetic views. No specific format or style of the entry work is designated.',
					}
				]
			},
			{
				title: ' Digital Creation ',
				conten: ' Design and production shall be done with a focus on digital and virtual products. Entries shall be in digital formats, or creative works that have been digitally and virtually transformed with digital technologies and computer technologies. Entry works can range from electronic sports, digital visual art, AR, animation and game IP, digital collection, to virtual interactions. Specific categories include but are not limited to various digital illustrations, GIF animated pictures, virtual character skins and clothing, virtual properties, virtual collection, memes, and IP images.',
				propositionTitle: ' Group of Designated Themes – “Culture & Trends”：',	
				propositionArr: [{
						title: ' National Trends（Chinese-Speaking Regions）',
						title2: ' National & Ethnic Designs（East Asia and Southeast Asia）',
						conten2: ' Design and production must be undertaken with a focus on ethnic (national) traditi8onal cultural elements, in integration with modern fashion and tread elements. For specific format or category, please refer to the requirements for the Group for Free Theme Entries.',
						conten: ' Traditional Chinese culture and intangible cultural heritage shall be integrated with current fashions and trends, so that creative works that feature a sense of epochal trends shall be designed. For the concrete formats or categories, please refer to the requirements for the Group for Free Theme Entries in Digital Creation.'
					},
					{
						title: '',
						title2: ' Culture & Diversity Designs（Europe and the Americas）',
						conten2: ' A reinterpretation of history and culture shall be carried out from a pluralistic perspective, and re-creation shall be performed in integration with digital representations. For specific format or category, please refer to the requirements for the Group for Free Theme Entries.',
						conten: ''
					}
				]
			}
		],
		respectfully: {

		},
		awardsTe: {
			title: ' Designations of Awards ',
			titleLfe: ' This Competition presents altogether ',
			awardsF: ' design awards ',
			group: ' Group ',
			arrTitle: [' The Track of People’s Premium Creation', ' The track of Praises for the City',
				' The Track of Digital Creation', ' The Track of People’s Premium Creation'
			],
		},
		dimensionalityArr: [{
				icon: require('@/assets/megagame1.png'),
				text: ' Safety ',
				conten: 'whether the target user will be free from harm physically, psychologically or otherwise, when using the product '
			},
			{
				icon: require('@/assets/megagame2.png'),
				text: ' Compliance ',
				conten: ' whether the design of the work complies with national laws and regulations or relevant requirements and standards in the industry '
			},
			{
				icon: require('@/assets/megagame3.png'),
				text: ' Accessibility ',
				conten: ' whether the target user can quickly master the methods of using the product and achieve the expected purposes or even exceed the expected purposes, without massive learning for it '
			},
			{
				icon: require('@/assets/megagame4.png'),
				text: ' Integrity ',
				conten: ' whether the work presents the applications, functions and componential elements of the product comprehensively and clearly '
			},
			{
				icon: require('@/assets/megagame5.png'),
				text: ' Innovativeness ',
				conten: ' whether the work has a novel design and has certain creative elements or functions '
			},
			{
				icon: require('@/assets/megagame6.png'),
				text: ' Beauty ',
				conten: ' whether the product expressed by the work conforms to the mainstream aesthetics of the society '
			},
			{
				icon: require('@/assets/megagame7.png'),
				text: ' Practicality ',
				conten: ' whether the work is closed related to general life of the public and has practical worth of use '
			},
			{
				icon: require('@/assets/megagame8.png'),
				text: ' Feasibility ',
				conten: ' whether the work can be mass produced industrially and offers a decent return on investment '
			},
			{
				icon: require('@/assets/megagame9.png'),
				text: ' Cultural content ',
				conten: ' whether the design of the work contains cultural elements or backgrounds, embodies cultural heritage, transmission and integration '
			},
			{
				icon: require('@/assets/megagame10.png'),
				text: ' Commercial value ',
				conten: ' whether the work will be accepted and bring economic benefits once launched into the market '
			},
			{
				icon: require('@/assets/megagame11.png'),
				text: ' Potentiality of the work ',
				conten: ' whether the work has large room for optimization or improvement in order to enhance its value '
			},
			{
				icon: require('@/assets/megagame12.png'),
				text: ' Sustainability ',
				conten: ' whether the product can be used for a sustained period, and whether the product conforms to concepts of environmental friendliness and conservation '
			},
			{
				icon: require('@/assets/megagame13.png'),
				text: ' Thematic relevance ',
				conten: ' whether the design of the product exhibits a high relevance to the track or thematic requirements '
			}
		],
		awardsce: [{
				title: 'Gold Prize',
				money: '300,000 CNY',
				icon: require('@/assets/j.png'),
				num: 1,
			},
			{
				title: 'Silver Prize',
				money: '100,000 CNY ',
				icon: require('@/assets/y.png'),
				num: 2,
			},
			{
				title: 'Bronze Prize',
				money: '20,000 CNY ',
				icon: require('@/assets/t.png'),
				num: 3,
			},
			{
				title: 'Best Industry Prize',
				money: '100,000 CNY ',
				icon: require('@/assets/q.png'),
				num: 1,
			},
			{
				title: 'Premium Industry Prize',
				money: '20,000 CNY ',
				icon: require('@/assets/q.png'),
				num: 2,
			},
			{
				title: 'Excellence Prize',
				money: '5,000 CNY ',
				icon: require('@/assets/q.png'),
				num: 31,
			}
		],
		micro: [{
				title: 'Best Judge of Talent Prize',
				money: '300,000 CNY ',
				icon: require('@/assets/q.png'),
				num: 5,
			},
			{
				title: ' Best Organization prize ',
				money: '100,000 CNY ',
				icon: require('@/assets/q.png'),
				num: 10,
			},
		],
		game: {
			freedom: [{
					title: 'Gold Prize',
					money: '200,000 CNY ',
					icon: require('@/assets/j.png'),
					num: 1,
				},
				{
					title: 'Silver Prizes',
					money: '100,000 CNY ',
					icon: require('@/assets/y.png'),
					num: 2,
				},
				{
					title: 'Bronze Prize',
					money: '20,000 CNY ',
					icon: require('@/assets/t.png'),
					num: 3,
				},

				{
					title: 'Excellence Prize',
					money: '5,000 CNY ',
					icon: require('@/assets/q.png'),
					num: 15,
				}
			],
			life: [{
					title: ' Best Creation Prize ',
					money: '200,000 CNY ',
					icon: require('@/assets/q.png'),
					num: 1,
				},
				{
					title: ' Premium Creation Prize ',
					money: '100,000 CNY ',
					icon: require('@/assets/q.png'),
					num: 2,
				},
				{
					title: 'Excellence Prize',

					money: '20,000 CNY ',
					icon: require('@/assets/q.png'),
					num: 6,
				}
			]
		},
		creativity: {
			freedom: [{
					title: 'Gold Prize',
					money: '100,000 CNY ',
					icon: require('@/assets/j.png'),
					num: 1,
				},
				{
					title: 'Silver Prizes',
					money: '50,000 CNY ',
					icon: require('@/assets/y.png'),
					num: 2,
				},
				{
					title: 'Bronze Prize',
					money: '10,000 CNY ',
					icon: require('@/assets/t.png'),
					num: 3,
				},

				{
					title: 'Excellence Prize',
					money: '5,000 CNY ',
					icon: require('@/assets/q.png'),
					num: 15,
				}
			],
			life: [{
					title: 'Best Creation Prize',
					money: '100,000 CNY ',
					icon: require('@/assets/q.png'),
					num: 1,
				},
				{
					title: 'Premium Creation Prizes',
					money: '50,000 CNY ',
					icon: require('@/assets/q.png'),
					num: 2,
				},
				{
					title: 'Excellence Prize',
					money: '5,000 CNY ',
					icon: require('@/assets/q.png'),
					num: 6,
				}
			]
		},
		sheet: [{
				title: ' Sponsor ',
				conten: ' Global People magazine，People’s Cultural Creation (Beijing) Cultural Development Company '
			},
			{
				title: ' Specially Support By ',
				conten: ' China International Culture Exchange Center, CCP Committee of Shangyu District of Shaoxing Municipality, Shangyu District Government of the Shaoxing Municipality '
			},
			{
				title: ' Organizer, Co-organizer ',
				conten: ' Global People Cultural Creation (Zhejiang) Cultural Development Company Ltd '
			},
			{
				title: ' Co-organizer ',
				conten: ' People’s Joy Works, People’s Education, Tencent '
			},
			{
				title: ' Supported By ',
				conten: ' People.cn, News.cn '
			}
		],
		awards: [{
				title: ' The Track of People’s Premium Creation',
				ranking: '40 awards ',
				money: '855,000',
				moneyName: ' Total Prizes ',
			},
			{
				title: ' The track of Praises for the City',
				ranking: '30 awards ',
				money: '965,000',
				moneyName: ' Total Prizes ',
			},
			{
				title: ' The Track of Digital Creation',
				ranking: '30 awards ',
				money: '535,000',
				moneyName: ' Total Prizes ',
			},
			// {
			// 	title: ' The Track of People’s Premium Creation',
			// 	ranking: '40 awards ',
			// 	money: '250,000',
			// 	moneyName: ' Total Prizes ',
			// }
		],
		DetailsIntroduced: ' Selection Standards ',
		dimensionality: {
			tilte: ' Dimensions for Evaluation and Selection ',
			conten: ' A complete and comprehensive system for evaluation has been designed for the Competition based on whole-chain links in dimensions of design, culture, creativity and others. For each link, experts in the corresponding fields shall be invited to do evaluation and selection based on evaluation dimensions designated for the link concerned.'
		},
		submission: {
			tilte: ' Access of Registration and Entry in the Competition and Requirements for Submission and Application ',
			conten: [
				'/ To enter the 2nd People’s Cultural Creation International Creative Competition, register and log in on the Competition’s official website and then fill in the contestant’s information as instructed. The contestant’s account is to be generated automatically. Afterwards, from this account, the contestant shall upload all entry works, download the electronic certificate, check for and inquire about evaluation and review results.',
				'/ All contestants shall use authentic information for registration and shall not register for competition accounts repeatedly. For violations in these two respects, the Organizing Committee shall reserve the right to annul the corresponding contestant’s qualifications for the Competition and suspend the corresponding account(s).',
				'/ The contestant can submit entry works within his or her competition account. There is no limit to the number of entry works from the same contestant, but the same entry work cannot be submitted repeatedly or be entered for more than one track or group. For repeated works, the Organizing Committee shall review and evaluate them for awards after elimination of the repetitions.',
				'/ The contestant shall have to be entitled to the complete ownership of the entry works. If organizations or institutions are submitting the works to the Competition on others’ behalf, authorization and confirmation have to be obtained from the owners of the works.',
				'/ All entry works have to be originally created by the designer. Others’ works shall not be plagiarized or appropriated. Meanwhile, entry works shall not involve anything obscene, violent, reactionary, racially prejudiced or in other ways violating laws and regulations and contradicting fundamental ethics and values.',
				'/ The Organizing Committee is entitled to making adjustments to the competition tracks and groups of the entry works according to competition rules and the actualities of the works.',
				'/ For all entry works, complete information must be provided as required by the registration system in the stage of registration. The Organizing Committee can ask contestants to make up for any information needed for the works, depending on the circumstances.',
				'/ Submitted materials of the entry works fall into obligatory and optional items. Discretion can be exercised as to whether optional materials shall be submitted according to the actualities of the contestant, although optional materials may become important evidence for review and evaluation when pictorial or textual introductions do not embody the materials, structure, mode of usage and others in details. Therefore, the Organizing Committee strongly recommends their submission. For specific requirements for the materials of the submissions, please refer to the new webpage formulated within the contestant’s account.',
				'/ At upload of the pictures of the entry works, they should be laid out with the corresponding templates as provided by the Organizing Committee. The templates shall not be redesigned or altered at discretion.',
				'/ During the registration stage of the Competition, only electronic materials shall be submitted. During the stage of the final review and evaluation, the actual works or their 3D print model shall have to be posted to the Organizing Committee. Otherwise, these shortlisted entry works shall not enter the final review or consultation. The expenses for the actual works or their 3D samples shall be undertaken by the contestants themselves. The specific time and address for the postal delivery shall be otherwise notified separately by the Organizing Committee.',
				'/ During the registration stage of the Competition, only electronic materials shall be submitted. During the stage of the final review and evaluation, the actual works or their 3D print model shall have to be posted to the Organizing Committee. Otherwise, these shortlisted entry works shall not enter the final review or consultation. The expenses for the actual works or their 3D samples shall be undertaken by the contestants themselves. The specific time and address for the postal delivery shall be otherwise notified separately by the Organizing Committee. For queries in the process of filling in your information and applying and of uploading the entry works, or in case of failure to upload or to display after upload, please contact the Organizing Committee for confirmation. Refer to the main page of the website for contact information.'
			],
			query: ' For queries in the process of filling in your information and applying and of uploading the entry works, or in case of failure to upload or to display after upload, please contact the Organizing Committee for confirmation. Refer to the main page of the website for contact information.'
		},
		palm: {
			title: ' Issuance of Awards and Prizes for Winning Works ',
			conten: [
				'/ Electronic certificates for all shortlisted entry works shall be issued by the Organizing Committee for free, which can be downloaded in the contestant’s competition account after the final awards have been announced.',
				'/ The Organizing Committee shall award corresponding physical certificates or cups or reward money in accordance with the different award categories of the entry works. Physical certificates and cups shall be awarded on the Award Ceremony of the Competition. Award winners who fail to accept the awards on site shall be deemed as having renounced the physical certificates and cups. The Organizing Committee shall not deliver them by post or provide re-issuance.',
				'/ Issuance of reward money shall proceed in accordance with the relevant laws and regulations of the People’s Republic of China. Winning contestants shall pay an income tax of 20% of their reward money as regulated. The tax shall be paid out by the Organizing Committee on behalf of the winner contestants. The ultimate sums for transfer shall be the balance after tax deductions.',
				'/ All reward money shall be issued by the Organizing Committee of the Competition by bank transfer. The recipient accounts within China can be those of the first author of the winner works, or those of the submission or application organizations, companies/work units. For overseas acceptance of the reward money, personal accounts have to be used. In case the recipient of the reward money is not the first author of the works, or if the recipient is not the same institution, company/work unit or individual as the submitter, written authorizations and explanations shall be submitted, with confirmations of signatures and stamps of both parties.',
				'/ If such legal and regulatory violations as plagiarization and piracy of winner works on the part of the contestant are found out after the issuance of the awards, the Organizing Committee of the Competition has the right to annul all involved awards, and revoke the certificates, cups and reward money.',
				'/ If the recommending party of the organization and sponsorship awards are found to have forged the numbers of recommendations, uploaded works of inferior qualities in batches, or cheated by adopting other people’s names, their qualifications for winning awards shall be revoked upon confirmation. The Organizing Committee shall hold those with gross offences to legal obligations and liabilities.'
			]
		},
		question: {
			title: ' Frequently Asked Questions ',
			conten: [{
					title: ' Question: Is entry in the Competition free?',
					conten: ' Answer: Yes, the PCCICC does not charge any fees for registration.'
				},
				{
					title: ' Question: Are works of teams or work units acceptable?',
					conten: ' Answer: Yes. Individuals, teams, and work units and organizations can all enter the Competition.'
				},
				{
					title: ' Question: Can I submit multiple entry works?',
					conten: ' Answer: Yes, individuals, teams, and work units can submit multiple entry works.'
				},
				{
					title: ' Question: Can I submit works that have won awards in other competitions?',
					conten: ' Answer: Yes, but you need to possess the complete ownership of the rights.'
				},
				{
					title: ' Question: Are participants outside China eligible to enter the Competition?',
					conten: ' Answer: Yes, this Competition is an international competition event, and we call for entry works around the world.'
				},
				{
					title: ' Question: Through what channels can one learn of the shortlisted outcomes for each phase of the Competition?',
					conten: ' Answer: After the completion of each phase of review and evaluation, the shortlisted outcomes of the corresponding phase shall be available from the registration system for checkup and confirmation.'
				},
				{
					title: ' Question: When does one have to provide a sample of the works?',
					conten: ' Answer: When the works have been reviewed and evaluated for the second round, the Organizing Committee shall schedule the shortlisted contestants to prepare the physical sample of the works. The expenses incurred shall be undertaken by the contestants themselves.'
				},

				{
					title: ' Question: For entry into the different tracks of the Competition, is one allowed to use one and the same registered account?',
					conten: ' Answer: One can upload entry works for different tracks in the same account, but it has to be different works for different tracks for uploading.'
				},
				{
					title: ' Question: In case filled personal information or information about the entry works contain errors, how can one revise in the system?',
					conten: ' Answer: Before the deadline of the registration for the Competition, one can revise the information in the registered account for the Competition.'
				},
				{
					title: ' Question: What can I do if I fail to upload pictures or videos of the entry works?',
					conten: '答：Answer: Please check for the document format and size to see if it meets the requirements; refresh the uploading webpage; try an alternative browser and see what happens. If upload is still impossible, please inquire with the Organizing Committee of the Competition by email.'
				}
			]
		},
		download: {
			title: ' Download ',
			conten: [{
					title: ' Rules for the Use of Titles and Designations of the Competition Awards and Logos ',
					url: ''
				},
				{
					title: ' Terms and Conditions for Entering Competition ',
					url: ''
				},
				{
					title: ' Terms and Conditions for Recommendation ',
					url: ''
				}
			]
		},
		relation: {
			title: ' Contact Information',
			conten: [{
					title: ' Tel：',
					ie: '0575-82878701'
				},
				{
					title: ' Address of the Secretariat of the Organizing Committee of the Competition：',
					ie: ' Building C2, Insigma Technology Park, Fuxing East Road, Cao’e Sub-District, Shangyu District, Shaoxing City, Zhejiang Province,China'
				},
				{
					title: ' Postcode：',
					ie: '312300'
				},
				{
					title: ' Email：',
					ie: 'pcciccservice@rmwc.com.cn'
				}
			]
		}
	},
	newWord: {
		judgeTitle: ' The Board of Judges',
		adver: 'AD',
		moreinform: ' More information ',
		peopleTile: ' The People’s Premium Creation ',
		peopleralize: ' Discovering new changes in life  Designing new styles of life ',
		peopleConten: ' Discovering new changes in life  Designing new styles of life ',
		HopeTitle: ' Singing praises of the cities ',
		Hoperalize: ' Exploring urban historical profundities  Creating urban cultural impressions ',
		HopeConten: ' Exploring urban historical profundities  Creating urban cultural impressions ',
		figureTitle: ' Digital creation ',
		figureralize: ' Applying the power of digital technologies  Expanding new frontiers of cultural creation ',
		figureConten: ' Applying the power of digital technologies  Expanding new frontiers of cultural creation ',
		entryTitle1: ' Editing status：',
		entryTitle2: ' Evaluation status：',
		entryTitle3: ' Evaluation result：'
	},
	newText: {
		msg1: " Work Information ",
		msg2: " Author information ",
		msg3: " Work condition ",
		msg4: " Marketing and Sales of works ",
		msg5: " Product Introduction ",
		msg6: " Related Products ",
		msg7: "source",
		msg8: " Select year ",
		msg9: "skip",
		msg10: " Please fill in the name of school ",
		msg11: " Please fill in school major ",
		msg12: " Please fill in the start time ",
		msg13: " Please fill in the end time ",
		msg14: ["male", "female"],
		msg15: " Please upload personal photo ",
		msg16: " Please enter your name ",
		msg17: " Please select gender ",
		msg18: " Please select nationality ",
		msg19: " Please select year of birth ",
		msg20: " Please input id card ",
		msg21: " Please enter your contact number ",
		msg22: " Please select city ",
		msg23: " Please enter full address ",
		msg24: " Please enter your company/organization ",
		msg25: " Please enter position ",
		msg26: " No certificate can be downloaded ",
		msg27: " No new message at present ",
		msg28: " Replication succeeded ",
		msg29: " Best Organization ",
		msg30: " Best Talent Award ",
		msg31: "yes",
		msg32: "no",
		msg33: " Password format error ",
		msg34: " Please enter your password again ",
		msg35: " Passwords entered twice are inconsistent ",
		msg36: " Old password cannot be empty ",
		msg37: " Password cannot be empty ",
		msg38: " Minimum 8-digit password ",
		msg39: " Password changed successfully ",
		msg40: " Saved successfully ",
		msg41: " No documents yet",
		msg42: " Download now ",
		msg43: " The Second Contest Collection ",
		msg44: " Countdown ",
		msg45: " Expired",
		msg46: " Submitted successfully ",
		msg47: " The call for works has closed ",
		msg48: [" Select track ", " Select theme "],
		msg49: " Please fill in author keywords ",
		msg50: " Upload data ",
		msg51: " Data download ",
		msg52: " All photos uploaded must use the template specified in the competition ",
		msg53: " Upload main image ",
		msg54: " Please upload the main image first ",
		msg55: " Upload instructions ",
		msg56: " Your browser does not support video playback ",
		msg57: " Upload video ",
		msg58: " Upload document ",
		msg59: " Work condition ",
		msg60: " Marketing and Sales of works ",
		msg61: " Awards of works (optional) ",
		msg62: " Saved successfully ",
		msg63: " Submitted successfully ",
		msg64: " Note: Before the preliminary evaluation, you can go to the entry page of your account to modify the entry information.",
		msg65: " Continue to add author information ",
		msg66: " Return to the entry page ",
		msg67: " Previous step ",
		msg68: "save",
		msg69: " Next step ",
		msg70: "submit",
		msg71: "sure",
		msg72: " Template download ",
		msg73: " Not download yet ",
		msg74: " Download now ",
		msg75: " Age cannot be empty ",
		msg76: " Please enter numeric value ",
		msg77: " Must be at least 18 years of age ",
		msg78: " Work uploaded",
		msg79 :" Message Notification ",  
		msg80 :" All messages ",  
		msg81 :" Personal ",  
		msg82 :" Log out"  ,
		msg83 :" Mailbox cannot be empty ",  
		msg84 :" Please enter a four-digit verification code ",  
		msg85 :" Sent successfully ",  
		msg86 :" Mailbox format error" ,
		msg87:'advertising',
		msg88:"All information",
		msg89:"no data",
		msg90 :" Registration means consent ",  
		msg91 :' Entry Instructions ',  
		msg92 :' Recommended Notes ',  
		msg101: 'and',  
		msg93 :' Registered successfully ',  
		msg94 :' Please enter content ',  
		msg95: 'gold',  
		msg96: 'silver',  
		msg97 :'2021 People is Cultural Innovation International Creative Competition '  ,
		msg98 :' Keyword ',  
		msg99 :' Search results ',  
		msg100: 'a'  ,
		msg102:'ID number',
		msg103:'Please enter your ID number',
		msg104:'Please input a password',
		msg105:'Saved successfully',
		msg106:'Please add at least one work picture ~',
		msg107:'Please add a description of the work ～',
		msg108:'Please select the number of works ~',
		// msg109:'',
		msg110:'Please add at least one work picture ~',
		msg111:'Please add at least one work picture ~ please choose whether to register copyright ~',
		msg112:'Please upload the copyright registration photo ~',
		msg113:'Please fill in the key words of the work',
		msg114:'No more than 3 keywords',
		msg115:'Keywords of works cannot be repeated',
		msg116:'No entries yet',
		msg117:'Please select a province / City',
		msg118:'Please upload pictures in the correct format',
		msg119:'Please upload PDF file',
		msg120:'Pdf files cannot exceed 10MB',
		msg121:'Please upload pictures in JPEG format',
		msg122:'Picture size cannot exceed 10MB',
		msg123:'Please upload pictures in PNG format',
		msg124:'Picture size cannot exceed 5MB',
		msg125:'Picture size cannot exceed 3MB',
		msg126:'Please upload the correct video format',
		msg127:'Video size cannot exceed 200MB',
		msg128:'Please upload the correct pptx format',
		msg129:'Ppt size cannot exceed 10MB',
		msg130:'Please upload the correct picture format',
		msg131:'Please upload the correct document format',
		msg132:'The document size cannot exceed 500m',
		msg133:'No data',
		msg134:'Please confirm whether to delete this work?',
		msg135:'Please check the instructions ~',
		msg136:'en'
	}
}
