// 导入  axios
import axios from 'axios'
import qs from 'qs'
import router from "../router";
import {
	Loading,
	Message
} from 'element-ui'
// import router from '../router/index'
// 导入qs 对post请求进行序列化，如果不需要序列化，可以不用导入

/*
 * 根据环境变量区分接口的默认地址
 * process.env.NODE_ENV     表示环境变量
 */
switch (process.env.NODE_ENV) {
	case 'production':
		// 发布上线、生产环境的请求地址
		axios.defaults.baseURL = 'https://v2admin.rmwc.com.cn/api/'
		break
	case 'test':
		// 测试环境的请求地址
		axios.defaults.baseURL = 'https://v2admin.rmwc.com.cn/api/'
		break
	default:
		// 除了生产环境、测试环境，剩下的环境，比如开发环境"development"
		axios.defaults.baseURL = 'https://v2admin.rmwc.com.cn/api/'
}

/*
 * 设置超时时间和跨域，是否允许携带凭证
 */
axios.defaults.timeout = 10000
axios.defaults.withCredentials = false

/*
 * 设置请求传递数据的格式（具体要看服务器要求什么格式，一般情况不用）
 * 比如 x-www-form-urlencoded
 */
axios.defaults.headers['Access-Control-Allow-Methods'] = 'POST,GET,OPTIONS,DELETE'
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.transformRequest = data => qs.stringify(data)

// 定义一个变量，用来控制请求时加载提示样式，前提是要全局安装了element-ui
let loadings;

/*
 * 设置请求拦截器 客户端发送请求->[ 请求拦截器 ] -> 服务器
 * TOEKN 校验（JWT）, 接收服务器返回的token，存储到vue/本地存储
 */
axios.interceptors.request.use(
	config => {
		// 携带上token
		const token = localStorage.getItem('token')
		// let lang = localStorage.getItem('languageSet') == 'en' ? 'en-us' : 'zh-cn'
		let lang='zh-cn'//打包
	config.url = config.url + '?lang=' + lang
	
		// 在每次发起请求在请求头上加上token值
		// 在每次请求获取数据之前都开启loading弹窗
		config.headers = {
			'content-type': 'application/x-www-form-urlencoded'
		}
		token && (config.headers.token = token)
		const option = {
				lock: true,
				background: 'rgba(250, 250, 250, 0)'
			},
			loadings = Loading.service(option)
		loadings.close()
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

/*
 * 响应拦截器
 * 服务器返回信息 -> [拦截器的统一处理] ->客户端js获取到信息
 */
axios.defaults.validateStatus = status => {
	// 自定义响应成功的HTTP状态码
	return /^(2|3)\d{2}$/.test(status)
}

axios.interceptors.response.use(
	response => {
		if (response.data.code == 1) {
			return response.data
		}else{
			Message.error({message:response.data.msg,offset:120})
		}

		loadings.close()
	},
	error => {
		const {
			response
		} = error
		if (response) {
			// 服务器返回了，最起码有结果
			switch (response.status) {
				case 401:
					Message.error(response.data.msg)
					// 当前请求需要用户验证（一般是未登录）
					// 下次有时间查资料整理此处跳转登录页面

					localStorage.removeItem('token'); //清除缓存
					router.push('/login')
					break
				case 403:
					// 服务器已经理解请求，但是拒绝执行它（一般是token过期）
					//token过期清除token	
					localStorage.removeItem('token')
					break
				case 0:
					// 请求失败，请求所希望得到的资源未被在服务器上发现
					// 可强制跳转到404页面
					Message.error('请求资源不存在！')
					break
				case 404:
					// 请求失败，请求所希望得到的资源未被在服务器上发现
					// 可强制跳转到404页面
					Message.error('请求资源不存在！')
					break
				case 500:
					// 服务器异常（一般是业务报的错）
					break
			}
		} else {
			// 服务器连结果都没有返回
			if (!window.navigator.onLine) {
				// 断网处理：可以跳转到断网页面
				return
			}
			return Promise.reject(error)
		}
	}
)
// 导出封装的axios
export default axios
