import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
	  navHader:0,
	  // 上传作品进度
	  schedule:0,
	  user_id:0,
	  headTxtIndex:'',
  },
  mutations: {
	  setScheDule(state,val){
		  console.log(val);
		  state.schedule=val;
	  },
	  setHeadIndex(state,val){
		state.headTxtIndex=val;  
	  },
	  amendUserId(state,val){
		  state.user_id=val
	  }
  },
  actions: {},
  modules: {},
});
