const router = {
	path: "/",
	name: "login",
	component: () => import( /* webpackChunkName: "about" */ "@/views/Enter/index.vue"),
	children: [{
			path: "logIn",
			name: "LogIn",
			component: () =>
				import( /* webpackChunkName: "about" */ "@/views/Enter/logIn.vue"),
		},
		{
			path: "NewPass",
			name: "newPass",
			component: () =>
				import( /* webpackChunkName: "about" */ "@/views/Enter/newPass.vue"),
		},
		{
			path: "about",
			name: "About",
			component: () =>
				import( /* webpackChunkName: "about" */ "@/views/About.vue"),
		},
		{
			path: "entry",
			name: "Entry",
			component: () =>
				import( /* webpackChunkName: "about" */ "@/views/Home/entry.vue"),
		},
		{
			path: "register",
			name: "Register",
			component: () =>
				import( /* webpackChunkName: "about" */ "@/views/Enter/register.vue"),
		},
		{
			// 忘记密码
			path: "forget",
			name: "Forget",
			component: () => import( /* webpackChunkName: "about" */ "@/views/Enter/forget.vue"),
		}
	]
}
export default router